import { IFilter } from "../../../shared/models/filters/filter.interface";
import { FilterEvent } from "../../../shared/models/filters/filter-event.interface";
import { IPlanboardVehicleView } from "../models/vehicle.model";
import { IPlanboardVehicleDataView } from "../../models/planboard-data.model";
import { IPlanboardSeatingArrangement } from "../../models/seating-arrangement.model";

export class SeatConfigurationFilter implements IFilter {
    private event: FilterEvent;

    constructor(event?: FilterEvent) {
        this.event = event;
    }

    meets(
        planboardData: IPlanboardVehicleDataView[],
    ): IPlanboardVehicleDataView[] {
        if (!this.event) {
            return planboardData;
        }
        if (!this.event.criteria) {
            return planboardData;
        }
        if (!this.event.criteria.length) {
            return planboardData;
        }

        const matches =
            (seats: IPlanboardSeatingArrangement[]) => (value: string) => {
                if (!seats.length) {
                    return;
                }
                return seats.some((seat) => seat.seatingTypeName === value);
            };

        const bySeatConfig = (bus: IPlanboardVehicleView) =>
            this.event.criteria
                .map((criteria) => criteria.value)
                .some(matches(bus.seatingArrangments));

        const reduce = (res, data) => {
            const vehicles = data.vehicles.filter(bySeatConfig);
            return vehicles.length ? [...res, { ...data, vehicles }] : res;
        };

        const result = planboardData.reduce(reduce, []);

        return result;
    }
}
