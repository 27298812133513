import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ToasterNotificationModule } from "src/app/shared/components/toaster-notification/toaster-notification.module";
import { SwitcherModule } from "../../shared/components/switcher/switcher.module";
import { SharedModule } from "../../shared/shared.module";
import { CanvasModule } from "../canvas/canvas.module";
import { PlanningBusesComponent } from "./buses.component";
import { PlanningBusesRoutingModule } from "./buses-routing.module";
import { BusCardModule } from "../components/bus-card/bus-card.module";
import { AssignConfirmationPopupModule } from "src/app/shared/components/assign-confirmation-popup/assign-confirmation-popup.module";
import { TripCardModule } from "../components/trip-card/trip-card.module";
import { EuronormPipe } from "./pipes/euronorm.pipe";
import { SortPipe } from "./pipes/sort.pipe";
import { TripPipe } from "./pipes/trip.pipe";
import { BusFilterPipe } from "./pipes/filter.pipe";
import { RenderablePipe } from "./pipes/canvas.pipe";
import { SpinnerLoaderModule } from "src/app/shared/components/spinner-loader/spinner-loader.module";
import { BusAvailabilitySelectionModule } from "../components/busSelectionComponent/busAvailabilitySelection.module";
import { StatusPipe } from "./pipes/status.pipe";
import { FilterPanelModule } from "../../shared/components/filter-panel/filter-panel-module";
import { LegendModule } from "../components/legend/legend.module";
import { AvailabilityPipe } from "./pipes/availability.pipe";
import { RowIndexPipe } from "./pipes/row-index.pipe";
import { ClearFiltersModule } from "../../shared/components/clear-filters/clear-filters.module";
import { CarFilterPipe } from "./pipes/carsFilter.pipe";
import { VehicleAvailabilityPipe } from "./pipes/vehicle-availability.pipe";
import { ModeSwitcherComponent } from "../components/mode-switcher/mode-switcher.component";
import { TrackRenderModule } from "../../shared/derectives/track-render/track-render.module";
import { TripDateFilterPipe } from "./pipes/trip-date-filter.pipe";
import { DriverCardModule } from "../components/driver-card/driver-card.module";

@NgModule({
    declarations: [
        AvailabilityPipe,
        BusFilterPipe,
        EuronormPipe,
        PlanningBusesComponent,
        RenderablePipe,
        RowIndexPipe,
        SortPipe,
        CarFilterPipe,
        StatusPipe,
        TripPipe,
        VehicleAvailabilityPipe,
        ModeSwitcherComponent,
        TripDateFilterPipe,
    ],
    imports: [
        AssignConfirmationPopupModule,
        BusCardModule,
        CanvasModule,
        CommonModule,
        PlanningBusesRoutingModule,
        SharedModule,
        SwitcherModule,
        ToasterNotificationModule,
        SpinnerLoaderModule,
        TripCardModule,
        BusAvailabilitySelectionModule,
        FilterPanelModule,
        LegendModule,
        ClearFiltersModule,
        TrackRenderModule,
        DriverCardModule,
    ],
    providers: [
        AvailabilityPipe,
        BusFilterPipe,
        CarFilterPipe,
        RenderablePipe,
        RowIndexPipe,
        TripDateFilterPipe,
        VehicleAvailabilityPipe,
    ],
})
export class PlanningBusesModule {}
