<div
    [ngClass]="{
        'trips-expanded': isTripsTabExpanded,
        'trips-collapsed': isTripsTabCollapsed,
    }"
    class="buses-content"
>
    <div class="ge-buses-row flex-fill ibox-content">
        <div
            class="ge-buses-first-col buses-first-col-height"
            id="busgedriverinfo"
        >
            <div class="drivers-filters-pane">
                <ge-filter-panel [useProvider]="'bus'">
                    <ng-container role="left_head">
                        <ge-range-selection-filter
                            (rangeSelection)="onDateChanged($event)"
                        ></ge-range-selection-filter>
                    </ng-container>
                    <ng-container role="left_tail">
                        <ge-clear-filters
                            class="button-text-no-wrap"
                            (click)="clearFilters()"
                        >
                        </ge-clear-filters>
                    </ng-container>
                </ge-filter-panel>
            </div>

            <div class="header d-flex position-relative">
                <div class="buses-table table--6cols">
                    <div class="buses-row buses-row--head">
                        <div class="buses-cell number column-heading"></div>
                        <div class="buses-cell sticker column-heading"></div>
                        <div class="buses-cell norm column-heading">Norm</div>
                        <div class="buses-cell touring column-heading">TC</div>
                        <div class="buses-cell comfort column-heading">CC</div>
                        <div class="buses-cell royal column-heading">RC</div>
                    </div>
                </div>
                <div class="canvas-container">
                    <ge-date-axis
                        [scaleConfiguration]="scaleConfiguration"
                    ></ge-date-axis>
                </div>

                <div class="legend-block" *ngIf="showPlanning$ | async">
                    <ge-legend></ge-legend>
                </div>
            </div>
            <ge-spinner-loader
                *ngIf="loading"
                [isMaxHeight]="true"
                [top]="'150px'"
            ></ge-spinner-loader>

            <div
                class="buses-data-wrapper"
                [ngStyle]="{ opacity: loading ? '0' : '1' }"
            >
                <ng-container *ngIf="transformedPlanboardData">
                    <div
                        class="ge-driver-info ge-planned-trip-wrapper d-flex flex-fill"
                        #scrollContainer
                        (scroll)="onScroll($event)"
                    >
                        <div class="buses-table table--6cols scroll-offset">
                            <div
                                *ngFor="
                                    let item of transformedPlanboardData;
                                    let last = last;
                                    let itemIndex = index;
                                    let first = first
                                "
                                [ngStyle]="
                                    last
                                        ? { 'margin-bottom': '40px' }
                                        : { 'margin-bottom': '0px' }
                                "
                            >
                                <h3
                                    class="garage-name"
                                    *ngIf="item.vehicles.length"
                                >
                                    <ge-svg
                                        icon="garage"
                                        color="#7f0025"
                                        class="mr-10"
                                    ></ge-svg>
                                    {{
                                        item.garage === "Third party garage"
                                            ? ("busListPage.planboard.otherGarages"
                                              | i18n)
                                            : item.garage
                                    }}
                                </h3>

                                <div
                                    *ngFor="
                                        let vehicle of item.vehicles;
                                        let vehicleIndex = index
                                    "
                                    geDroppable
                                    class="buses-row drop-target pointer highlighted"
                                    [class.inactive]="vehicle.isInactive"
                                    [ngClass]="
                                        this.isDragging
                                            ? getHighlightClassName(vehicle)
                                            : null
                                    "
                                    [class.hovered]="
                                        !isDragging &&
                                        vehicleIndex +
                                            getExtraIndex(
                                                transformedPlanboardData,
                                                itemIndex
                                            ) +
                                            (itemIndex + 1) ===
                                            selectedRowIndex
                                    "
                                    [class.selected]="
                                        selectedRowIndex === vehicle.index
                                    "
                                    (geDrop)="onDrop($event, vehicle)"
                                    (geDropEnter)="onDrag($event, vehicle)"
                                    (click)="showBusCard(vehicle)"
                                    (mouseover)="
                                        onBusHover(
                                            vehicleIndex +
                                                getExtraIndex(
                                                    transformedPlanboardData,
                                                    itemIndex
                                                ) +
                                                (itemIndex + 1)
                                        )
                                    "
                                    (mouseout)="onBusHover(null)"
                                >
                                    <div
                                        class="maindata trirdpartydriver"
                                        *ngIf="
                                            isVehicleOfType(
                                                vehicle,
                                                vehicleType.Rental
                                            )
                                        "
                                    >
                                        {{ vehicle.brand }}
                                    </div>
                                    <ng-container
                                        *ngIf="
                                            !isVehicleOfType(
                                                vehicle,
                                                vehicleType.Rental
                                            )
                                        "
                                    >
                                        <div class="buses-cell number">
                                            <div class="buses-cell--heading">
                                                #
                                            </div>
                                            <div
                                                class="buses-cell--content maindata"
                                            >
                                                {{ vehicle.number }}
                                            </div>
                                        </div>
                                        <div class="buses-cell sticker">
                                            <div class="buses-cell--heading">
                                                Sticker
                                            </div>
                                            <div class="buses-cell--content">
                                                {{ vehicle.stickerName }}
                                            </div>
                                        </div>
                                        <div class="buses-cell norm">
                                            <div class="buses-cell--heading">
                                                Norm
                                            </div>
                                            <div
                                                class="buses-cell--content"
                                                *ngIf="
                                                    !isVehicleOfType(
                                                        vehicle,
                                                        vehicleType.Rental
                                                    )
                                                "
                                            >
                                                <div
                                                    class="circle"
                                                    [ngStyle]="{
                                                        background: vehicle
                                                            .euronorm.value
                                                            ? vehicle.euronorm
                                                                  .color
                                                            : 'white',
                                                    }"
                                                >
                                                    <span>{{
                                                        vehicle.euronorm?.value
                                                            | euronorm
                                                    }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div class="buses-cell touring">
                                        <div class="buses-cell--heading hidden">
                                            TC
                                        </div>
                                        <div class="buses-cell--content">
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    touring;
                                                    context: getVehicleSeatsTemplateOptions(
                                                        vehicle,
                                                        'touring'
                                                    )
                                                "
                                            ></ng-container>
                                        </div>
                                    </div>
                                    <div class="buses-cell comfort">
                                        <div class="buses-cell--heading hidden">
                                            CC
                                        </div>
                                        <div class="buses-cell--content">
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    comfort;
                                                    context: getVehicleSeatsTemplateOptions(
                                                        vehicle,
                                                        'comfort'
                                                    )
                                                "
                                            ></ng-container>
                                        </div>
                                    </div>
                                    <div
                                        class="buses-cell buses-cell--foot royal"
                                    >
                                        <div class="buses-cell--heading hidden">
                                            RC
                                        </div>
                                        <div class="buses-cell--content">
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    royal;
                                                    context: getVehicleSeatsTemplateOptions(
                                                        vehicle,
                                                        'royal'
                                                    )
                                                "
                                            ></ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="no-search-result"
                                *ngIf="!$any(transformedPlanboardData).length"
                                data-translate-me
                                [translateMe]="
                                    'busListPage.filters.filterResult'
                                "
                            >
                                {{ "busListPage.filters.filterResult" | i18n }}
                            </div>
                        </div>

                        <div class="canvas-container" #container>
                            <app-canvas
                                [data]="canvasData"
                                [isParentScroll]="isScrolling"
                                [contextName]="canvasContextNames.busses"
                                [onCanvasClick]="openCreateModal"
                            >
                            </app-canvas>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="ge-buses-second-col buses-second-col-height">
            <ge-tabs-container
                [tabs]="tabsConfiguration"
                [type]="'trips'"
                (collapseTripsTab)="handleCollapseTripsTab($event)"
                (expandTripsTab)="handleExpandTripsTab($event)"
            >
                <div class="controls-container" controls-slot>
                    <div class="controls-container__period">
                        <ge-range-selection-filter
                            (rangeSelection)="onDateChanged($event)"
                        ></ge-range-selection-filter>
                    </div>
                    <div class="controls-container__toggles">
                        <ge-switch-toggle
                            [options]="durationTypeToggleConfig.options"
                            [text]="durationTypeToggleConfig.text"
                            (optionSelected)="durationTimeTypeChanged($event)"
                        ></ge-switch-toggle>
                        <ge-switch-toggle
                            [options]="
                                driverVehicleAvailabilityStatusTypeToggleConfig.options
                            "
                            [text]="
                                driverVehicleAvailabilityStatusTypeToggleConfig.text
                            "
                            (optionSelected)="availabilityStatusChanged($event)"
                        ></ge-switch-toggle>
                        <ge-switch-toggle
                            [options]="vehicleTypeToggleConfig.options"
                            [text]="vehicleTypeToggleConfig.text"
                            (optionSelected)="vehicleTypeChanged($event)"
                        ></ge-switch-toggle>
                        <ge-switch-toggle
                            [options]="creationModeToggleConfig.options"
                            [text]="creationModeToggleConfig.text"
                            (optionSelected)="creationModeChanged($event)"
                        ></ge-switch-toggle>
                    </div>
                    <ge-period-selector
                        (scaleIndexChanged)="onScaleIndexChanged($event)"
                        [scaleIndex]="
                            scaleConfiguration.scaleConfigurationIndex
                        "
                        [isGridView]="true"
                    >
                    </ge-period-selector>
                </div>
            </ge-tabs-container>
        </div>
    </div>
</div>

<ge-bus-card
    *ngIf="busToShow"
    [isShow]="showBusDetail"
    (close)="closeBusInfo()"
    [busId]="busToShow.id"
>
</ge-bus-card>

<ge-driver-card
    *ngIf="isDriverInfoShown"
    (close)="closeDriverCard()"
    [isShow]="isDriverInfoShown"
    [driverId]="driverToShow.id"
>
</ge-driver-card>

<ge-trip-card
    *ngIf="showTripDetail"
    (hideComponent)="onHideTripCard()"
    (openDriverCard)="showDriverCard($event)"
    [positionType]="'buses'"
></ge-trip-card>

<ng-template #touring let-seats="seats" let-color="color">
    <div class="seat" *ngIf="seats" [ngStyle]="{ background: color }">
        <p>{{ seats }}</p>
    </div>
</ng-template>

<ng-template #comfort let-seats="seats" let-rest="restSeats" let-color="color">
    <div class="seat" *ngIf="seats" [ngStyle]="{ background: color }">
        <p [ngStyle]="{ 'margin-left': rest ? '-5px' : '0px' }">
            {{ seats }}<sup *ngIf="rest">{{ rest }}</sup>
        </p>
    </div>
</ng-template>

<ng-template #royal let-seats="seats" let-rest="restSeats" let-color="color">
    <div class="seat" *ngIf="seats" [ngStyle]="{ background: color }">
        <p [ngStyle]="{ 'margin-left': rest ? '-5px' : '0px' }">
            {{ seats }}<sup *ngIf="rest">{{ rest }}</sup>
        </p>
    </div>
</ng-template>

<ng-container #container></ng-container>
