/* src/app/planning/components/mode-switcher/mode-switcher.component.scss */
.mode-switcher {
  display: flex;
  border-radius: 2px;
  font-size: 12px;
}
.mode-switcher.disabled {
  pointer-events: none;
  opacity: 0.7;
}
.mode {
  padding: 4px 10px;
  border: 1px solid #dddddd;
  cursor: pointer;
  text-align: center;
}
.mode.active {
  border-color: #5095c1;
  background: #f2f8fb;
  color: #5095c1;
}
.mode.left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.mode.right {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.mode.left:not(.active) {
  border-right-width: 0;
}
.mode.right:not(.active) {
  border-left-width: 0;
}
/*# sourceMappingURL=mode-switcher.component.css.map */
