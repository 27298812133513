import { Component } from "@angular/core";
import { IModalWindowOptions } from "src/app/shared/components/modal-window/modal-options.interface";
import { ModalWindowService } from "src/app/shared/components/modal-window/modal-window.service";
import { I18nPipe } from "src/app/shared/i18n/i18n.pipe";

interface IManualReturnTripDialogData {
    isCar: boolean;
    isDestinationGarage: boolean;
}
@Component({
    selector: "ge-mannual-return-trip-dialog",
    templateUrl: "./mannual-return-trip-dialog.component.html",
    styleUrl: "./mannual-return-trip-dialog.component.scss",
})
export class MannualReturnTripDialogComponent {
    data: IManualReturnTripDialogData = {} as IManualReturnTripDialogData;

    modalWindowOptions: IModalWindowOptions = {
        title: "busListPage.mannualReturnTripPopup.title",
        isNotCancellable: true,
        buttons: [
            {
                label: "busListPage.mannualReturnTripPopup.cancel",
                theme: "default",
                click: this.close.bind(this),
            },
            {
                label: "busListPage.mannualReturnTripPopup.withSameDrivers",
                click: this.close.bind(this, { withSameDrivers: true }),
            },
            {
                label: "busListPage.mannualReturnTripPopup.withOtherDrivers",
                click: this.close.bind(this, { withOtherDrivers: true }),
            },
        ],
    };

    constructor(
        private i18n: I18nPipe,
        private modalWindowService: ModalWindowService,
    ) {}

    close(selectedConfig: any) {
        const config = {
            withOtherDrivers: false,
            withSameDrivers: false,
            ...selectedConfig,
        };

        this.modalWindowService.close(config);
    }

    get message() {
        return this.i18n.transform(
            "busListPage.mannualReturnTripPopup.message",
            {
                type: this.i18n.transform(
                    `busListPage.mannualReturnTripPopup.${this.data.isCar ? "car" : "bus"}`,
                ),
            },
        );
    }
}
