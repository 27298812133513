import { Pipe, PipeTransform } from "@angular/core";
import { Observable } from "rxjs";
import { defaultIfEmpty, map } from "rxjs/operators";
import { IBus } from "../models/vehicle.model";

@Pipe({
    name: "sort",
    pure: true,
})
export class SortPipe implements PipeTransform {
    transform(buses$: Observable<IBus[]>): Observable<IBus[]> {
        const isRental = (first: IBus, second: IBus) => {
            if (!first.isRental && !second.isRental) {
                return 0;
            }
            if (first.isRental) {
                return 1;
            }
            return -1;
        };

        return buses$.pipe(
            defaultIfEmpty([]),
            map((buses: IBus[]) => buses.sort(isRental)),
        );
    }
}
