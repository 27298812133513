<div class="mode-switcher" [class.disabled]="isDisabled">
    <div
        class="mode left"
        (click)="toggle(modes.Trip)"
        [class.active]="selectedMode === modes.Trip"
    >
        {{ "busListPage.filters.tripMode" | i18n }}
    </div>
    <div
        class="mode right"
        (click)="toggle(modes.Activity)"
        [class.active]="selectedMode === modes.Activity"
    >
        {{ "busListPage.filters.activityMode" | i18n }}
    </div>
</div>
