import { Pipe, PipeTransform } from "@angular/core";
import { Observable } from "rxjs";
import { IPlanboardVehicleDataView } from "../../models/planboard-data.model";
import { map } from "rxjs/operators";
import { cloneDeep } from "lodash";
import { THIRD_PARTY_GARAGE } from "../const/const";

@Pipe({
    name: "tripDateFilter",
})
export class TripDateFilterPipe implements PipeTransform {
    transform(
        planboardData: Observable<IPlanboardVehicleDataView[]>,
    ): Observable<IPlanboardVehicleDataView[]> {
        return planboardData.pipe(
            map((garages) => {
                return cloneDeep(garages).map((garage) => {
                    if (garage.garage === THIRD_PARTY_GARAGE) {
                        garage.vehicles = garage.vehicles.filter(
                            ({ trips }) => trips.length,
                        );
                    }
                    return garage;
                });
            }),
        );
    }
}
