import { Pipe, PipeTransform } from "@angular/core";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { ITrip } from "../../trips/models/trip.model";
import { IBus } from "../models/vehicle.model";

@Pipe({
    name: "trip",
    pure: true,
})
export class TripPipe implements PipeTransform {
    transform(
        buses$: Observable<IBus[]>,
        trips$: BehaviorSubject<ITrip[]>,
    ): Observable<IBus[]> {
        return combineLatest(
            buses$,
            trips$,
            (buses: IBus[], trips: ITrip[]) => {
                if (!buses || !buses.length) {
                    return [];
                }
                if (!trips || !trips.length) {
                    return buses;
                }

                return (
                    buses
                        .map((bus: IBus) => ({
                            ...bus,
                            trips: trips.filter(
                                (trip) => trip.busId === bus.id,
                            ),
                        }))

                        // In order to support 3rd party buses we need to take into account that same bus can have
                        // only one trip assigned, so if more than one trip assigned to 3rd party bus - we need to  split it
                        .flatMap((bus: IBus): IBus[] => {
                            if (bus.isRental && bus.trips.length > 0) {
                                return bus.trips.map(
                                    (trip: ITrip, i: number): IBus => ({
                                        ...bus,
                                        trips: bus.trips.slice(i, i + 1),
                                    }),
                                );
                            } else {
                                return [bus];
                            }
                        })
                );
            },
        );
    }
}
