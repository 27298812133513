import { Pipe, PipeTransform } from "@angular/core";
import { map } from "rxjs/operators";

@Pipe({
    name: "rowIndex",
    pure: true,
})
export class RowIndexPipe implements PipeTransform {
    transform(data$: any, ...args: any[]): any {
        return data$.pipe(
            map((data: any[]) => {
                data.flatMap((value) => [{}, ...value.vehicles]).forEach(
                    (vehicle, index) => (vehicle.index = index),
                );

                return data;
            }),
        );
    }
}
