import { IFilter } from "../../../shared/models/filters/filter.interface";
import { FilterEvent } from "../../../shared/models/filters/filter-event.interface";
import { filterData } from "../../common/filters/search-filters.functions";
import { IPlanboardVehicleDataView } from "../../models/planboard-data.model";

export class BusSearchFilter implements IFilter {
    private event: FilterEvent;

    constructor(event: FilterEvent) {
        this.event = event;
    }

    meets(
        planboardData: IPlanboardVehicleDataView[],
    ): IPlanboardVehicleDataView[] {
        if (!this.event) {
            return planboardData;
        }
        if (!this.event.criteria) {
            return planboardData;
        }
        if (!this.event.criteria.length) {
            return planboardData;
        }
        if (!this.event.criteria[0].query) {
            return planboardData;
        }

        const query = this.event.criteria[0].query.toLowerCase();
        if (!query) {
            return planboardData;
        }

        return filterData(planboardData, query, "vehicles");
    }
}
