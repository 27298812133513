import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BusTabMode } from "../../buses/models/bus-tab-mode.model";

@Component({
    selector: "ge-mode-switcher",
    templateUrl: "./mode-switcher.component.html",
    styleUrl: "./mode-switcher.component.scss",
})
export class ModeSwitcherComponent {
    @Input()
    isDisabled = false;

    @Input()
    selectedMode: BusTabMode;

    @Output()
    modeChanged = new EventEmitter<BusTabMode>();

    modes = BusTabMode;

    toggle(mode: BusTabMode) {
        this.modeChanged.emit(mode);
    }
}
