import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "../../../shared/shared.module";
import { FormsModule } from "@angular/forms";
import { BusCardComponent } from "./bus-card.component";
import { BusCardService } from "./bus-card.service";
import { AutosizeModule } from "ngx-autosize";
import { NgClickOutsideDelayOutsideDirective } from "ng-click-outside2";

@NgModule({
    declarations: [BusCardComponent],
    imports: [
        AutosizeModule,
        CommonModule,
        FormsModule,
        NgClickOutsideDelayOutsideDirective,
        SharedModule,
    ],
    exports: [BusCardComponent],
    providers: [BusCardService],
})
export class BusCardModule {}
