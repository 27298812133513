import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PlanningBusesComponent } from "./buses.component";

const routes: Routes = [
    {
        path: "",
        component: PlanningBusesComponent,
        children: [
            { path: "", redirectTo: "trips", pathMatch: "full" },
            {
                path: "trips",
                loadChildren: () =>
                    import("../trips/trips.module").then(
                        (m) => m.PlanningTripsModule,
                    ),
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PlanningBusesRoutingModule {}
