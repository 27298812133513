import { IFilter } from "../../../shared/models/filters/filter.interface";
import { FilterEvent } from "../../../shared/models/filters/filter-event.interface";
import { IPlanboardVehicleView } from "../models/vehicle.model";
import { IPlanboardVehicleDataView } from "../../models/planboard-data.model";

export class TripAssignementFilter implements IFilter {
    private event: FilterEvent;

    constructor(event?: FilterEvent) {
        this.event = event;
    }

    meets(
        planboardData: IPlanboardVehicleDataView[],
    ): IPlanboardVehicleDataView[] {
        if (
            !this.event ||
            !this.event.criteria ||
            !this.event.criteria.length
        ) {
            return planboardData;
        }

        const filterTripsForVehicle = (vehicle: IPlanboardVehicleView) => {
            const filteredTrips = vehicle.trips.filter((trip) =>
                this.event.criteria.some(
                    (criteria) => criteria.value === trip.status,
                ),
            );

            return filteredTrips.length
                ? { ...vehicle, trips: filteredTrips }
                : null;
        };

        return planboardData.reduce((res, data) => {
            const vehicles = data.vehicles
                .map(filterTripsForVehicle)
                .filter(Boolean);

            if (vehicles.length) {
                return [...res, { ...data, vehicles }];
            }

            return res;
        }, []);
    }
}
