import { Pipe, PipeTransform } from "@angular/core";
import { IPlanboardVehicleDataView } from "../../models/planboard-data.model";
import { Observable } from "rxjs";
import { map } from "rxjs/internal/operators";
import moment from "moment";
import { VehicleAvailabilityStatusesEnum } from "../models/vehicle-availability-statuses.enum";
import { IPlanboardVehicleView } from "../models/vehicle.model";

@Pipe({
    name: "availability",
})
export class AvailabilityPipe implements PipeTransform {
    transform(
        data: Observable<IPlanboardVehicleDataView[]>,
        ...args: any[]
    ): Observable<IPlanboardVehicleDataView[]> {
        const [dateRange] = args;
        const { from, to } = dateRange;

        const isVisibleByInactiveStatuses = (availabilityStatuses): boolean => {
            return availabilityStatuses.some((status) => {
                const statusFromDate = moment(status.startDate);
                const statusToDate = status.endDate && moment(status.endDate);
                const startIsBetween = statusFromDate.isBetween(from, to);
                const endIsBetween =
                    statusToDate && statusToDate.isBetween(from, to);

                return startIsBetween || endIsBetween;
            });
        };

        const byAvailabilityStatus = (vehicle: IPlanboardVehicleView) => {
            let availabilityStatuses = vehicle.availabilityStatuses;

            const inactiveStatuses = [
                VehicleAvailabilityStatusesEnum.INACTIVE,
                VehicleAvailabilityStatusesEnum.FOR_SALE,
                VehicleAvailabilityStatusesEnum.DELETED,
            ];
            const activeStatus = [VehicleAvailabilityStatusesEnum.ACTIVE];

            availabilityStatuses = availabilityStatuses.filter(
                (s) =>
                    inactiveStatuses.includes(s.status) &&
                    !activeStatus.includes(s.status),
            );

            const isVisible =
                !availabilityStatuses.length ||
                isVisibleByInactiveStatuses(availabilityStatuses);

            vehicle.isInactive = availabilityStatuses.length && isVisible;

            return isVisible;
        };

        const reduce = (res, data: IPlanboardVehicleDataView) => {
            const vehicles = data.vehicles.filter(byAvailabilityStatus);

            return vehicles.length ? [...res, { ...data, vehicles }] : res;
        };

        return data.pipe(
            map((data) => {
                // data = data.splice(0, 1);
                // data[0].vehicles = data[0].vehicles.splice(0, 3);

                const result = data.reduce(reduce, []);

                return result;
            }),
        );
    }
}
