/* src/app/planning/buses/buses.component.scss */
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
ge-tabs-container::ng-deep .page__top li,
ge-tabs-container::ng-deep .page__top a {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  position: relative;
}
ge-tabs-container::ng-deep .filters-pane,
ge-tabs-container::ng-deep .page__top li,
ge-tabs-container::ng-deep .page__top a {
  background-color: #f3f4f7 !important;
}
ge-tabs-container::ng-deep .tabs-content {
  background: white;
}
ge-tabs-container::ng-deep .filters-pane {
  height: 100px;
  padding: 8px 0 8px 14px;
}
.pointer {
  cursor: pointer;
}
.drivers-filters-pane {
  display: flex;
  flex-direction: column;
  margin: 20px 23px 12px 13px;
}
.ibox-content {
  position: relative;
  padding: 0;
}
ge-tabs-container::ng-deep .page__top span {
  font-size: 16px;
  font-weight: bold;
  color: #0b162d;
  position: relative;
}
.canvas-container {
  width: calc(100% - 400px);
}
.seats-width {
  width: 40px;
  padding-left: 20px;
}
ge-bus-card {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
  position: absolute;
  top: 0;
  right: 0;
}
.ge-driver-info,
.ge-planned-trip-wrapper {
  height: calc(100% - 112px);
  overflow-y: auto;
}
.number {
  width: 15%;
  text-align: left;
}
.sticker {
  width: 30%;
  text-align: start;
}
.norm {
  width: 16%;
}
.touring {
  width: 13%;
}
.comfort {
  width: 13%;
}
.royal {
  width: 13%;
}
.maindata {
  font-size: 14px;
  color: #0b162d;
}
.circle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  font-size: 12px;
  color: #ffffff;
  transform: translateY(6px);
  margin: 0 auto;
}
.circle span {
  position: absolute;
  transform: translate(-50%, -20%);
}
.seat {
  width: 38px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid rgba(219, 223, 232, 0.5) !important;
  transform: translateY(4px);
  margin: 0 auto;
}
.seat p {
  position: relative;
  top: -5px;
}
.buses-table {
  width: 400px;
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  margin: 0;
  padding: 0;
  text-align: center;
}
.buses-table .garage-name {
  text-align: left;
  padding-left: 18px;
  height: 30px;
  margin: 0;
  display: flex;
  align-items: center;
}
.buses-table .buses-row {
  padding-left: 18px;
  width: 100%;
  height: 30px;
  display: flex;
  line-height: 30px;
  flex-shrink: 0;
}
.buses-table .buses-row.inactive .number,
.buses-table .buses-row.inactive .maindata {
  color: #adadad;
}
.buses-table .buses-row .buses-cell {
  box-sizing: border-box;
  justify-content: center;
  flex-grow: 1;
  overflow: hidden;
}
.buses-table .buses-row .buses-cell.column-heading {
  background-color: #ffffff;
  color: #828282;
  border-bottom: 1px solid #f3f4f7;
  font-size: 11px;
}
.buses-table .buses-row .buses-cell .buses-cell--heading {
  display: none;
}
.buses-table .buses-row--head {
  height: 41px;
  align-items: flex-end;
}
.buses-table .buses-row--head .drivers-cell {
  line-height: 30px;
}
.buses-table .buses-row .drop-target.hightlighted {
  background: rgba(128, 128, 128, 0.4);
}
.buses-table .buses-row.hovered {
  background: #f6f7f9;
}
.scroll-offset:last-child {
  margin-bottom: 40px;
}
.success {
  background: rgba(74, 217, 162, 0.21) !important;
}
.not-allowed {
  background: rgba(255, 87, 121, 0.2) !important;
}
.warning {
  background: rgba(255, 199, 87, 0.2) !important;
}
.ge-driver-info-height {
  height: 80vh !important;
}
.actions {
  width: 154px;
  height: 38px;
  border-radius: 4px;
  box-shadow: 0 4px 12px 0 rgba(170, 24, 58, 0.3);
  background-color: #aa183a;
  color: #ffffff;
  display: inline-block;
}
.actions-tooltip {
  position: absolute;
  display: none;
  width: 205px;
  padding: 8px 14px 10px 14px;
  background: #0b162d;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.142564);
  border-radius: 4px;
  font-size: 11px;
  line-height: 15px;
  color: #ffffff;
  top: -56px;
  left: -21px;
}
.actions-tooltip-triangle {
  position: absolute;
  width: 10px;
  height: 10px;
  bottom: -5px;
  left: 50%;
  transform: rotate(45deg);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.142564);
  background-color: #0b162d;
}
.disabled {
  position: relative;
}
.disabled .actions {
  pointer-events: none;
  opacity: 0.3;
}
.disabled:hover .actions-tooltip {
  display: block;
}
.flex-fill {
  display: flex;
  flex-direction: inherit;
}
.ge-buses-row {
  background: white;
  height: calc(100vh - 89px);
  width: calc(100vw - 268px);
  flex-direction: row;
  flex-wrap: wrap;
}
:host-context(.menu-collapsed) .ge-buses-row {
  width: calc(100vw - 120px);
}
.buses-second-col-height {
  height: 50%;
}
.buses-first-col-height {
  max-height: calc(100vh - 136px);
  height: 50%;
  width: 100%;
}
:root {
  --screen-width: 1980px;
}
@media (max-width: 1980px) {
  .ge-driver-bus-info-height {
    height: calc(100% - 75px);
  }
}
@media (min-width: 1980px) and (max-width: 2941px) {
  .buses-second-col-height {
    max-height: calc(100vh - 136px);
    height: 50%;
    width: 100%;
  }
  .ge-driver-bus-info-height {
    height: calc(100% - 75px);
  }
}
@media (min-width: 2941px) {
  .buses-first-col-height {
    height: 50vh;
    width: 100%;
  }
  .buses-second-col-height {
    max-height: calc(100vh - 136px);
    width: 100%;
  }
  .ge-driver-bus-info-height {
    height: 100%;
  }
}
.buses-second-col-height {
  z-index: 2;
}
.legend-block {
  position: absolute;
  right: 20px;
  top: 365px;
  z-index: 1;
}
.no-search-result {
  display: flex;
  margin-left: 10px;
}
.selected {
  position: relative;
}
::ng-deep .dragable-item {
  background: red;
  width: 100px;
  height: 10px;
  position: absolute;
}
.trirdpartydriver {
  width: 235px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
}
.mode-wrapper {
  display: flex;
  align-items: center;
}
.filter-switcher ::ng-deep {
  width: 100%;
}
.filter-switcher ::ng-deep .toggle {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.buses-data-wrapper {
  height: 100%;
}
#busgedriverinfo .garage-name {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
}
.controls-container {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 20px;
  opacity: 0;
  animation: appear 1s ease-in-out forwards;
}
.controls-container__period {
  margin-left: 20px;
}
.controls-container__toggles {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(4, 1fr);
  flex: 1;
  align-items: center;
  margin-right: auto;
}
:host ::ng-deep .ge-buses-second-col ul.tabs {
  align-items: center;
  width: 100%;
}
.controls-container__period {
  display: none;
}
.ge-buses-second-col {
  background-color: white;
}
.trips-expanded .controls-container__period {
  display: block;
}
.trips-expanded .ge-buses-second-col {
  position: absolute;
  top: 0;
}
.trips-collapsed .ge-buses-first-col {
  height: 95%;
}
.trips-collapsed .ge-buses-second-col {
  position: absolute;
  top: calc(100% - 44px);
}
::ng-deep .set-bus-capacity .modal-dialog {
  width: 280px;
}
:host {
  position: relative;
}
ge-driver-card {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
}
/*# sourceMappingURL=buses.component.css.map */
