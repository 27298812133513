import { Pipe, PipeTransform } from "@angular/core";
import { Observable, combineLatest } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import {
    IPlanboardVehicleDataView,
    IAvailabilitySettings,
} from "../../models/planboard-data.model";
import { IPlanboardVehicleView } from "../models/vehicle.model";
import { isNonOccupiedTimeAtLeastOneHour } from "../../common/functions/helper";

@Pipe({
    name: "vehicleAvailability",
})
export class VehicleAvailabilityPipe implements PipeTransform {
    transform(
        data$: Observable<IPlanboardVehicleDataView[]>,
        settings: IAvailabilitySettings,
    ): Observable<IPlanboardVehicleDataView[]> {
        const { isOnlyAvailable$, currentPeriod } = settings;
        const filterDataByNonOccupiedTime = (
            data: IPlanboardVehicleDataView[],
            from: Date,
            to: Date,
        ): IPlanboardVehicleDataView[] => {
            return data.reduce(
                (
                    res: IPlanboardVehicleDataView[],
                    item: IPlanboardVehicleDataView,
                ) => {
                    const vehicles = item.vehicles.filter(
                        (vehicle: IPlanboardVehicleView) =>
                            isNonOccupiedTimeAtLeastOneHour(vehicle, from, to),
                    );
                    return vehicles.length
                        ? [...res, { ...item, vehicles }]
                        : res;
                },
                [],
            );
        };

        return combineLatest([data$, isOnlyAvailable$]).pipe(
            map(([data, isOnlyAvailable]) => {
                if (!isOnlyAvailable || data.length === 0) {
                    return data;
                }
                return filterDataByNonOccupiedTime(
                    data,
                    currentPeriod.dateFrom,
                    currentPeriod.dateTo,
                );
            }),
            debounceTime(0),
        );
    }
}
