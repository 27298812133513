<div
    class="componentContainer d-flex flex-column"
    *ngIf="isShow"
    (clickOutside)="onClickedOutside($event)"
    [delayClickOutsideInit]="true"
>
    <!--    TODO: check delayClickOutsideInit and clickOutside in whole application and fix errors -->
    <ng-container *ngIf="bus">
        <div class="header d-flex">
            <div class="avatar">
                <img
                    alt="bus"
                    *ngIf="bus.mainPhoto"
                    src="{{ host + bus.mainPhoto.url }}"
                />
                <img
                    alt="bus"
                    *ngIf="!bus.mainPhoto"
                    src="{{ 'assets/imgs/busSmall.png' }}"
                />
            </div>

            <div class="headerText d-flex flex-column">
                <div class="d-flex align-items-center">
                    <a
                        href="{{ busLink }}"
                        target="_blank"
                        rel="noopener"
                        class="driverName"
                        ># {{ bus.number }}</a
                    >

                    <div class="bus-status {{ currentBusStatus }}">
                        {{ currentBusStatus }}
                    </div>
                </div>
                <p class="busName">{{ bus.brand }}</p>
                <p class="driverInfo textGray" *ngIf="bus.euronorm">
                    <span
                        class="euroNorm"
                        [style.background-color]="bus.euronorm.color"
                    >
                        {{ bus.euronorm.value.match(fil)[0] }}
                    </span>
                    <span class="dot"></span>
                    {{ bus.licensePlate.toUpperCase() }}
                </p>
            </div>

            <div class="sitsContainer d-flex flex-column">
                <div class="sitsHead d-flex">
                    <div class="sitsItem d-flex flex-column">
                        <p class="textGray" style="text-align: center">TC</p>
                        <div
                            class="items23"
                            [ngStyle]="{
                                background:
                                    bus.availableSeatConfiguration[0].color,
                            }"
                        >
                            <p class="sits">
                                {{
                                    bus.availableSeatConfiguration[0].seats || 0
                                }}
                            </p>
                            <span></span>
                        </div>
                    </div>
                    <div
                        class="sitsItem d-flex flex-column align-items-center"
                        *ngIf="bus.__typename === 'BusVehicleGraphType'"
                    >
                        <p class="textGray">CC</p>
                        <ng-container *ngIf="bus.availableSeatConfiguration[1]">
                            <div
                                class="items23"
                                [ngStyle]="{
                                    background:
                                        bus.availableSeatConfiguration[1].color,
                                }"
                            >
                                <p
                                    class="sits"
                                    *ngIf="
                                        bus.availableSeatConfiguration[1]
                                            .name === 'Comfort'
                                    "
                                >
                                    {{
                                        bus.availableSeatConfiguration[1]
                                            .seats || 0
                                    }}
                                </p>
                                <span
                                    *ngIf="
                                        bus.availableSeatConfiguration[1]
                                            .name === 'Comfort' &&
                                        bus.availableSeatConfiguration[1]
                                            .restSeats
                                    "
                                >
                                    {{
                                        bus.availableSeatConfiguration[1]
                                            .restSeats
                                    }}
                                </span>
                            </div>
                        </ng-container>
                    </div>
                    <div
                        class="sitsItem d-flex flex-column align-items-center"
                        *ngIf="bus.__typename === 'BusVehicleGraphType'"
                    >
                        <p class="textGray">RC</p>
                        <div
                            class="items23"
                            [ngStyle]="{
                                background:
                                    bus.availableSeatConfiguration[2]?.color,
                            }"
                        >
                            <p
                                class="sits"
                                *ngIf="
                                    bus.availableSeatConfiguration[1] &&
                                    bus.availableSeatConfiguration[1].name ===
                                        'Royal'
                                "
                            >
                                {{
                                    bus.availableSeatConfiguration[1].seats || 0
                                }}
                            </p>
                            <p
                                class="sits"
                                *ngIf="
                                    bus.availableSeatConfiguration[2] &&
                                    bus.availableSeatConfiguration[2].name ===
                                        'Royal'
                                "
                            >
                                {{
                                    bus.availableSeatConfiguration[2].seats || 0
                                }}
                            </p>
                            <span
                                *ngIf="
                                    bus.availableSeatConfiguration[1] &&
                                    bus.availableSeatConfiguration[1]
                                        .restSeats &&
                                    bus.availableSeatConfiguration[1].name ===
                                        'Royal'
                                "
                            >
                                {{
                                    bus.availableSeatConfiguration[1].restSeats
                                }}
                            </span>
                            <span
                                *ngIf="
                                    bus.availableSeatConfiguration[2] &&
                                    bus.availableSeatConfiguration[2]
                                        .restSeats &&
                                    bus.availableSeatConfiguration[2].name ===
                                        'Royal'
                                "
                            >
                                {{
                                    bus.availableSeatConfiguration[2].restSeats
                                }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="close pointer" (click)="onClose()">
                <ge-svg icon="cross" size="10"></ge-svg>
            </div>
        </div>
        <div class="scrollContainer">
            <ge-scroll-view>
                <p
                    class="basicInfo"
                    data-translate-me
                    [translateMe]="'busCardInfo.basicInformation'"
                >
                    {{ "busCardInfo.basicInformation" | i18n }}
                </p>
                <div class="infoBlock d-flex flex-column">
                    <div
                        class="InfoBlockLabel d-flex"
                        *ngIf="bus.__typename === 'BusVehicleGraphType'"
                    >
                        <p
                            class="textGray hdr"
                            data-translate-me
                            [translateMe]="'busCardInfo.busType'"
                        >
                            {{ "busCardInfo.busType" | i18n }}
                        </p>
                        <p class="infoBlockText" *ngIf="bus.busType">
                            {{ bus.busType.value | busName }}
                        </p>
                        <p class="infoBlockText" *ngIf="!bus.busType">-</p>
                    </div>
                    <div class="InfoBlockLabel d-flex">
                        <p
                            class="textGray hdr"
                            data-translate-me
                            [translateMe]="'busCardInfo.currentGarage'"
                        >
                            {{ "busCardInfo.currentGarage" | i18n }}
                        </p>
                        <p class="infoBlockText">{{ bus.location.value }}</p>
                    </div>

                    <div
                        class="InfoBlockLabel d-flex"
                        *ngIf="bus.__typename === 'BusVehicleGraphType'"
                    >
                        <p
                            class="textGray hdr"
                            data-translate-me
                            [translateMe]="'busCardInfo.stickered'"
                        >
                            {{ "busCardInfo.stickered" | i18n }}
                        </p>
                        <p class="infoBlockText" *ngIf="bus.stickering">
                            {{ bus.stickering.value }}
                        </p>
                        <p class="infoBlockText" *ngIf="!bus.stickering">-</p>
                    </div>
                </div>

                <div class="infoBlock2 d-flex flex-column">
                    <div
                        class="InfoBlockLabel d-flex"
                        *ngIf="bus.__typename === 'BusVehicleGraphType'"
                    >
                        <p
                            class="textGray hdr"
                            data-translate-me
                            [translateMe]="'busCardInfo.height'"
                        >
                            {{ "busCardInfo.height" | i18n }}
                        </p>
                        <p class="infoBlockText">
                            {{
                                (bus.height / 100).toString().replace(".", ",")
                            }}
                            m
                        </p>
                    </div>
                    <div
                        class="InfoBlockLabel d-flex"
                        *ngIf="bus.__typename === 'BusVehicleGraphType'"
                    >
                        <p
                            class="textGray hdr"
                            data-translate-me
                            [translateMe]="'busCardInfo.length'"
                        >
                            {{ "busCardInfo.length" | i18n }}
                        </p>
                        <p class="infoBlockText">
                            {{
                                (bus.length / 100).toString().replace(".", ",")
                            }}
                            m
                        </p>
                    </div>
                    <div
                        class="InfoBlockLabel d-flex"
                        *ngIf="bus.__typename === 'BusVehicleGraphType'"
                    >
                        <p
                            class="textGray hdr"
                            data-translate-me
                            [translateMe]="'busCardInfo.axes'"
                        >
                            {{ "busCardInfo.axes" | i18n }}
                        </p>
                        <p class="infoBlockText">{{ bus.numberOfAxes }}</p>
                    </div>
                    <div
                        class="InfoBlockLabel d-flex"
                        *ngIf="bus.__typename === 'BusVehicleGraphType'"
                    >
                        <p
                            class="textGray hdr"
                            data-translate-me
                            [translateMe]="'busCardInfo.vinNumber'"
                        >
                            {{ "busCardInfo.vinNumber" | i18n }}
                        </p>
                        <p class="infoBlockText">{{ bus.vin }}</p>
                    </div>

                    <p
                        class="basicInfo"
                        data-translate-me
                        [translateMe]="'busCardInfo.fuelInfo'"
                    >
                        {{ "busCardInfo.fuelInfo" | i18n }}
                    </p>

                    <div class="InfoBlockLabel d-flex">
                        <p
                            class="textGray hdr"
                            data-translate-me
                            [translateMe]="'busCardInfo.pinnInternalCard'"
                        >
                            {{ "busCardInfo.pinnInternalCard" | i18n }}
                        </p>
                        <p class="infoBlockText">{{ bus.fuelCardNumber }}</p>
                    </div>
                    <div class="InfoBlockLabel d-flex">
                        <p
                            class="textGray hdr"
                            data-translate-me
                            [translateMe]="'busCardInfo.pinBpCard'"
                        >
                            {{ "busCardInfo.pinBpCard" | i18n }}
                        </p>
                        <p class="infoBlockText">{{ bus.fuelCardPin }}</p>
                    </div>
                    <div class="InfoBlockLabel d-flex">
                        <p
                            class="textGray hdr"
                            data-translate-me
                            [translateMe]="'busCardInfo.fuelCapacity'"
                        >
                            {{ "busCardInfo.fuelCapacity" | i18n }}
                        </p>
                        <p class="infoBlockText">{{ bus.fuelCapacity }} L</p>
                    </div>
                    <div class="InfoBlockLabel d-flex">
                        <p
                            class="textGray hdr"
                            data-translate-me
                            [translateMe]="'busCardInfo.fuelType'"
                        >
                            {{ "busCardInfo.fuelType" | i18n }}
                        </p>
                        <p class="infoBlockText">{{ bus.fuelType }}</p>
                    </div>
                </div>

                <p
                    class="attrString"
                    data-translate-me
                    [translateMe]="'busCardInfo.attributes'"
                >
                    {{ "busCardInfo.attributes" | i18n }}
                </p>

                <div class="attributes d-flex">
                    <div class="d-flex flex-column attrItems">
                        <div
                            class="attrYellow"
                            *ngIf="bus.busAttributes.isWheelchairBus"
                        >
                            {{ "busModal.wheelchairBus" | i18n }}
                        </div>
                        <div
                            class="attrYellow"
                            *ngIf="bus.busAttributes.isManualGear"
                        >
                            {{ "busModal.manualGear" | i18n }}
                        </div>
                    </div>
                </div>
            </ge-scroll-view>
        </div>
    </ng-container>

    <div class="popUp d-flex" *ngIf="deleteNote || editNote">
        <div
            class="popDelete d-flex flex-column align-items-center"
            *ngIf="deleteNote"
        >
            <p
                class="attrString"
                data-translate-me
                [translateMe]="'busCardInfo.sureToDelete'"
            >
                {{ "busCardInfo.sureToDelete" | i18n }}
            </p>
            <div class="noteContainer d-flex flex-column">
                <div class="d-flex">
                    <p
                        class="textGray"
                        data-translate-me
                        [translateMe]="'busCardInfo.addedOn'"
                    >
                        {{ "busCardInfo.addedOn" | i18n }}
                        {{ noteToDisplay.date | date: "E M-d-yy" }}
                        {{ "busCardInfo.by" | i18n }}
                        {{ noteToDisplay.creator.displayName }}
                    </p>
                </div>
                <p class="noteDelete">
                    {{ noteToDisplay.commentText }}
                </p>
            </div>
            <div class="d-flex deleteButtons justify-content-between">
                <div
                    class="cancelButton btn align-self-start"
                    (click)="deleteNote = !deleteNote"
                    data-translate-me
                    [translateMe]="'busCardInfo.cancel'"
                >
                    {{ "busCardInfo.cancel" | i18n }}
                </div>
                <div
                    class="deleteButton btn align-self-end"
                    (click)="noteDelete()"
                    data-translate-me
                    [translateMe]="'busCardInfo.delete'"
                >
                    {{ "busCardInfo.delete" | i18n }}
                </div>
            </div>
        </div>

        <div
            class="popDelete d-flex flex-column align-items-center"
            *ngIf="editNote"
        >
            <p
                class="attrString"
                data-translate-me
                [translateMe]="'busCardInfo.editNote'"
            >
                {{ "busCardInfo.editNote" | i18n }}
            </p>
            <div class="addNotes d-flex">
                <textarea
                    autosize
                    [(ngModel)]="noteText"
                    data-translate-me
                    [translateMe]="'busCardInfo.typeNote'"
                    placeholder="{{ 'busCardInfo.typeNote' | i18n }}"
                    type="text"
                ></textarea>
            </div>
            <div class="d-flex deleteButtons justify-content-between">
                <div
                    class="cancelButton btn align-self-start"
                    (click)="editNote = !editNote; noteText = ''"
                    data-translate-me
                    [translateMe]="'busCardInfo.cancel'"
                >
                    {{ "busCardInfo.cancel" | i18n }}
                </div>
                <div
                    class="deleteButton btn align-self-end"
                    (click)="noteEdit()"
                    data-translate-me
                    [translateMe]="'busCardInfo.save'"
                >
                    {{ "busCardInfo.save" | i18n }}
                </div>
            </div>
        </div>
    </div>
</div>
