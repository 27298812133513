import {
    Component,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    Output,
} from "@angular/core";
import { environment } from "src/environments/environment";
import { BusCardService } from "./bus-card.service";
import { IBusAttributes, IBusCard } from "./model/bus-card.model";
import { Note } from "./note.interface";
import { Subscription } from "rxjs";
import { ScaleConfigurationBaseComponent } from "../scale-base-configuration/scale-configuration-base.component";
import moment from "moment";
import {
    VehicleStatusesEnum,
    BusStatusesMapToString,
} from "../../../data/busses/models/statuses.enum";
import { isClickedInside } from "../../planning-outside-click-helper";
import { excludeClasses } from "../../planning-outside-click-classes";

const attributes = {
    busType: ["50"],
    busLength: ["12"],
    busSpecial: ["Wheelchair"],
};
const SEATING_TYPES = {
    Touring: "TC",
    Comfort: "CC",
    Royal: "RC",
};

@Component({
    selector: "ge-bus-card",
    templateUrl: "./bus-card.component.html",
    styleUrl: "./bus-card.component.scss",
})
export class BusCardComponent
    extends ScaleConfigurationBaseComponent
    implements OnChanges, OnDestroy
{
    fil = /\d+/;
    attributes: IBusAttributes = attributes;
    host: string = environment.host;
    showMoreInfo = false;
    SEATING_TYPES = SEATING_TYPES;
    $bus: Subscription;
    $notes: Subscription;
    $editNotes: Subscription;
    $deleteNotes: Subscription;
    editedNoteText = "";
    bus: IBusCard;
    note: Note;
    noteText: string;
    busLink: string;
    entityType = "Vehicle";
    showAddNotes = false;
    editNote = false;
    deleteNote = false;
    noteToDisplay: any;
    currentBusStatus: string;

    @Input() busId: number;
    @Input() isShow = false;
    @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        injector: Injector,
        private busCardService: BusCardService,
        private el: ElementRef,
    ) {
        super(injector);
        this.busCardService.busCardDataSubject.subscribe((busIds) => {
            this.busId = busIds["busId"];
            this.fetchBus();
        });
    }
    ngOnChanges() {
        this.showMoreInfo = false;
        this.fetchBus();
        this.busCardService.setbusId(this.busId);
    }

    fetchBus() {
        const dateFrom = this.scaleConfiguration.dateRange.from;
        const dateTo = this.scaleConfiguration.dateRange.to;
        return (this.$bus = this.busCardService
            .fetchBus(this.busId, dateFrom, dateTo)
            .subscribe((bus) => {
                this.bus = bus;
                this.busLink = this.host + "/data/busses?busId=" + this.busId;
                this.setCurrentBusStatus(bus.availabilityStatus);
            }));
    }

    onShowMoreInfo() {
        this.showMoreInfo = !this.showMoreInfo;
    }

    onClose() {
        this.isShow = false;
        return this.close.emit();
    }

    onAddNote() {
        if (this.noteText) {
            this.note = {
                entityId: this.busId,
                entityType: this.entityType,
                commentText: this.noteText,
            };
            this.$notes = this.busCardService
                .addNotes(this.note)
                .subscribe((resp) => {
                    if (resp) {
                        this.fetchBus();
                    }
                });
            this.noteText = "";
        }
    }

    onShowPopUp(comment, type: string) {
        this.noteToDisplay = comment;

        if (type === "delete") {
            this.deleteNote = !this.deleteNote;
        } else {
            this.noteText = comment.commentText;
            this.editedNoteText = comment.commentText;
            this.editNote = !this.editNote;
        }
    }

    noteDelete() {
        this.$deleteNotes = this.busCardService
            .deleteNote(this.noteToDisplay.id)
            .subscribe((resp) => {
                if (resp) {
                    this.fetchBus();
                }
            });
        this.deleteNote = !this.deleteNote;
    }

    noteEdit() {
        if (this.editedNoteText === this.noteText) {
            this.editNote = !this.editNote;
            this.noteText = "";
            this.editedNoteText = "";
        } else {
            this.$editNotes = this.busCardService
                .editComment(this.noteToDisplay.id, this.noteText)
                .subscribe((resp) => {
                    if (resp) {
                        this.fetchBus();
                    }
                });
            this.editNote = !this.editNote;
            this.noteText = "";
            this.editedNoteText = "";
        }
    }

    toggleNote(note: any): void {
        note.showFullNote = !note.showFullNote;
    }

    ngOnDestroy() {
        const subscription = [
            this.$bus,
            this.$notes,
            this.$deleteNotes,
            this.$editNotes,
        ];
        subscription.map((el) => {
            if (el) {
                el.unsubscribe();
            }
        });
        super.ngOnDestroy();
    }

    // Set current bus availability status
    private setCurrentBusStatus(status: {
        status: VehicleStatusesEnum;
        startDate: string;
        endDate: string;
    }) {
        const now = moment();

        if (
            status.status === VehicleStatusesEnum.ACTIVE ||
            now.isBefore(status.startDate) ||
            now.isAfter(status.endDate)
        ) {
            this.currentBusStatus =
                BusStatusesMapToString[VehicleStatusesEnum.ACTIVE];
            return;
        }

        this.currentBusStatus =
            BusStatusesMapToString[VehicleStatusesEnum[status.status]];
    }

    onClickedOutside(e: Event) {
        const event = e.target as HTMLInputElement;
        if (
            !this.el.nativeElement.contains(e.target) &&
            !event.closest(".buses-row") &&
            !isClickedInside(event, excludeClasses)
        ) {
            this.onClose();
        }
    }
}
