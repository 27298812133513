import { Pipe, PipeTransform } from "@angular/core";
import { FilterPipe } from "../../../shared/pipes/filter.pipe";
import { FilterEvent } from "src/app/shared/models/filters/filter-event.interface";
import { IFilter } from "src/app/shared/models/filters/filter.interface";
import { BusFilterSettingsStore } from "../store/filter-settings-store";
import { BusSearchFilter } from "../filters/bus-search.filter";
import { BusTypeFilter } from "../filters/bus-type.filter";
import { SeatConfigurationFilter } from "../filters/seat-configuration.filter";
import { GarageLocationFilter } from "../filters/bus-garage.filter";
import { Observable } from "rxjs";
import { TripAssignementFilter } from "../filters/trips-assignement.filter";
import { TripTypeFilter } from "../filters/trip-type.filter";

@Pipe({
    name: "filter",
    pure: true,
})
export class BusFilterPipe extends FilterPipe implements PipeTransform {
    constructor(protected store: BusFilterSettingsStore) {
        super(store);
    }

    transform(observables$: Observable<any[]>): Observable<any[]> {
        return super.transform(observables$);
    }

    protected getFilter(event: FilterEvent): IFilter {
        const filters = {
            busGarage: new GarageLocationFilter(event),
            seatConfiguration: new SeatConfigurationFilter(event),
            busTripAssignement: new TripAssignementFilter(event),
            tripTypes: new TripTypeFilter(event),
            search: new BusSearchFilter(event),
            busType: new BusTypeFilter(event),
            // busAssignement: new BusAssignementFilter(event),
            // busLength: new BusLengthFilter(event),
            // busRental: new BusRentalFilter(event),
        };
        return filters[event.filterName];
    }
}
