import { IFilter } from "../../../shared/models/filters/filter.interface";
import { FilterEvent } from "../../../shared/models/filters/filter-event.interface";
import { IPlanboardVehicleView } from "../models/vehicle.model";
import { OrderType } from "../../canvas/const/order-type.enum";

const WITHOUT_ORDER = "WithoutOrder";
const CAR_TRIP = "CarTrip";

export class TripTypeFilter implements IFilter {
    private event: FilterEvent;

    constructor(event?: FilterEvent) {
        this.event = event;
    }

    meets(planboardData: IPlanboardVehicleView[]): IPlanboardVehicleView[] {
        if (
            !this.event ||
            !this.event.criteria ||
            !this.event.criteria.length
        ) {
            return planboardData;
        }

        const criteriaValues = this.event.criteria.map(
            (criteria) => criteria.value,
        );

        if (criteriaValues.includes(OrderType.MDT)) {
            criteriaValues.push(OrderType.Viabus);
            criteriaValues.push(OrderType.Sunweb);
        }

        const includesWithoutOrder = criteriaValues.includes(WITHOUT_ORDER);

        const matches = (orderType: string[]) => (value: string) =>
            orderType.some((type) => type === value);

        const filterTripsForVehicle = (vehicle: IPlanboardVehicleView) => {
            const filteredTrips = vehicle.trips.filter((trip) => {
                if (
                    includesWithoutOrder &&
                    !trip.orderType &&
                    trip.tripType === CAR_TRIP
                ) {
                    return true;
                }
                return criteriaValues.some(matches([trip.orderType]));
            });

            return { ...vehicle, trips: filteredTrips };
        };

        const reduce = (res, data) => {
            const vehiclesWithFilteredTrips = data.vehicles
                .map(filterTripsForVehicle)
                .filter((vehicle) => vehicle.trips.length > 0);

            return vehiclesWithFilteredTrips.length
                ? [...res, { ...data, vehicles: vehiclesWithFilteredTrips }]
                : res;
        };

        return planboardData.reduce(reduce, []);
    }
}
