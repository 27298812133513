import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";
import { Observable, BehaviorSubject, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { IPlanboardVehicleDataView } from "../../models/planboard-data.model";
import { VehicleType } from "../models/vehicle.model";
import { DEFAULT_VEHICLE_DATA_SHOW } from "../../common/const/const";

@Pipe({
    name: "carFilter",
    pure: true,
})
export class CarFilterPipe implements PipeTransform {
    transform(
        data$: Observable<IPlanboardVehicleDataView[]>,
        vehicleToShow$: BehaviorSubject<string>,
    ): Observable<IPlanboardVehicleDataView[]> {
        return combineLatest([data$, vehicleToShow$]).pipe(
            map(([data, vehicleToShow]) => {
                if (vehicleToShow === DEFAULT_VEHICLE_DATA_SHOW) {
                    return data;
                }

                return _.cloneDeep(data).map((d) => {
                    d.vehicles = d.vehicles.filter((vehicle) =>
                        this.shouldIncludeVehicle(
                            vehicle.vehicleType,
                            vehicleToShow,
                        ),
                    );
                    return d;
                });
            }),
        );
    }

    shouldIncludeVehicle(vehicleType: string, vehicleToShow: string): boolean {
        switch (vehicleToShow) {
            case "Bus":
                return (
                    vehicleType === VehicleType.Bus ||
                    vehicleType === VehicleType.Rental
                );
            case "Car":
                return vehicleType === VehicleType.Car;
            default:
                return false;
        }
    }
}
