/* src/app/planning/components/bus-card/bus-card.component.scss */
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
* {
  margin: 0;
  padding: 0;
}
.dot {
  width: 3px;
  height: 3px;
  border-radius: 1.5px solid #9b9fa8;
  background-color: #9b9fa8;
  display: inline-block;
  margin: 0 6px 3px 6px;
}
.textGray {
  font-size: 11px;
  color: #828282;
}
.pointer {
  cursor: pointer;
}
.componentContainer {
  position: relative;
  width: 444px;
  height: 787px;
  border-radius: 2px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
  background-color: white;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 18px;
  z-index: 2;
}
.headerText .busName {
  font-size: 12px;
  color: #0b162d;
  margin-bottom: 7px;
}
.headerText .driverName {
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;
  color: #0b162d;
  font-weight: 600;
  line-height: 1.13;
}
.close {
  margin-left: auto;
}
.avatar {
  margin-right: 14px;
}
.avatar img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}
.infoBlock {
  margin-top: 16px;
}
.InfoBlockLabel {
  margin-right: 20px;
  margin-top: 14px;
}
.infoBlockText {
  font-size: 12px;
  color: #0b162d;
  margin-left: 9px;
}
.moreInfo {
  cursor: pointer;
  margin-top: 16px;
  text-decoration: underline;
}
.attrString {
  font-size: 16px;
  color: #0b162d;
  margin-top: 24px;
  font-weight: 600;
}
.attributes {
  margin-top: 16px;
  padding-bottom: 16px;
}
.attributes .attrItems {
  width: auto;
}
.attributes .attrItems p {
  font-size: 14px;
  color: #0b162d;
  margin-right: 8px;
  padding: 1px 8px 1px 8px;
  border-radius: 4px;
  margin-top: 11px;
}
.attributes .attrBlue {
  background-color: #cfe0ff;
}
.attributes .attrYellow {
  background-color: #ffe5b0;
  margin-bottom: 5px;
  padding-left: 3px;
  border-radius: 4px;
}
.notesPart {
  margin-top: 8px;
  margin-bottom: 20px;
}
.notesPart .addNotePart {
  align-items: center;
}
.notesPart .addNotePart div {
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  width: 70px;
  height: 16px;
  border-radius: 10px;
  border: solid 1px rgba(219, 223, 232, 0.5);
}
.notesPart .addNotePart div p {
  margin-left: 4px;
  font-size: 11px;
  color: #0b162d;
}
.notesPart .displayNotes {
  margin-top: 12px;
}
.notesPart .displayNotes .note {
  height: auto;
  max-width: 370px;
  white-space: pre-line;
  word-wrap: break-word;
  margin-top: 6px;
  margin-bottom: 4px;
  font-size: 14px;
  color: #0b162d;
}
.sitsContainer {
  margin-left: 31px;
}
.sitsHead {
  margin-top: 8px;
}
.sitsHead .sitsItem {
  margin-left: 3px;
}
.sitsHead .sitsItem div {
  position: relative;
}
.sitsHead .sitsItem div span {
  font-size: 10px;
  color: #0b162d;
  position: absolute;
  right: 6px;
  top: 0;
  margin-left: 3px;
}
.euroNorm {
  width: 18px;
  height: 18px;
  border-radius: 9px;
  color: white;
  padding-left: 5px;
  padding-right: 2px;
  font-size: 13px;
}
.sits {
  width: 38px;
  height: 22px;
  border-radius: 4px;
  font-size: 14px;
  color: #0b162d;
}
.currentSitsType {
  background-color: #f7f7f7;
  width: 40px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid rgba(219, 223, 232, 0.5);
  text-align: center;
}
.items23 {
  text-align: center;
  border-radius: 4px;
  border: solid 1px rgba(219, 223, 232, 0.5);
}
.basicInfo {
  font-size: 16px;
  color: #0b162d;
  font-weight: 600;
  margin-top: 24px;
}
.scrollContainer {
  height: 689px;
}
.addNotes textarea {
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  display: block;
  padding: 6px 12px;
  -webkit-transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 87%;
  resize: none;
  height: 60px;
  margin-top: 10px;
  float: left;
}
.add-note-btn {
  height: 56px;
  margin-top: 10px;
  color: #ffffff;
  background-color: #7f0025;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 0 solid rgba(219, 223, 232, 0.5);
  padding: 1px 7px 2px 7px;
}
.button-disabled {
  opacity: 0.3;
  pointer-events: none;
}
.noteContainer {
  margin-top: 10px;
}
.editDelet {
  margin-left: auto;
}
.editDelet .edit {
  cursor: pointer;
}
.editDelet .delete {
  cursor: pointer;
  margin-right: 24px;
  margin-left: 14px;
}
.popUp {
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 200px;
  opacity: 1;
  background-color: white;
  border: 1px solid rgba(219, 223, 232, 0.5);
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
  width: calc(100% - 60px);
  padding: 10px 20px;
}
.popUp .deleteButtons {
  margin-top: 14px;
  width: 200px;
}
.cancelButton {
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid rgba(219, 223, 232, 0.5);
  border-radius: 4px;
}
.deleteButton {
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid rgba(219, 223, 232, 0.5);
  border-radius: 4px;
  background-color: #7f0025;
  color: white;
}
.pointer {
  cursor: pointer;
}
.more {
  cursor: pointer;
  color: #98002c;
  font-size: 12px;
}
.noteDelete {
  height: auto;
  max-width: 250px;
  white-space: normal;
  word-wrap: break-word;
  margin-top: 6px;
  margin-bottom: 4px;
  font-size: 14px;
  color: #0b162d;
}
.bus-status {
  margin-left: 10px;
  border-radius: 40px;
  padding: 3px 13px;
  text-transform: capitalize;
  color: #ffffff;
}
.bus-status.inactive {
  background: #e1c260;
}
.bus-status.dismissed {
  background: #ff9292;
}
/*# sourceMappingURL=bus-card.component.css.map */
