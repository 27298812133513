import { Injector, Pipe, PipeTransform } from "@angular/core";
import { ICanvasDataItem } from "../../canvas/models/canvas-configuration.interface";
import { CanvasDataItem } from "../../canvas/services/canvas.service";
import { combineLatest, Observable } from "rxjs";
import { IPlanboardVehicleView } from "../models/vehicle.model";
import { IPlanboardVehicleDataView } from "../../models/planboard-data.model";

export enum ClickHandlerType {
    Trip,
    Status,
}
export class CanvasPipedata {
    grossTime$: Observable<boolean>;
    clickHandlers: Map<ClickHandlerType, (...args: any) => void>;
    isMdtPlaning = false;
}
@Pipe({
    name: "canvas",
    pure: true,
})
export class RenderablePipe implements PipeTransform {
    constructor(injector: Injector) {
        CanvasDataItem.injector = injector;
    }

    transform(
        data: IPlanboardVehicleDataView[],
        CanvasPipedata,
    ): Observable<ICanvasDataItem[]> {
        return combineLatest(CanvasPipedata.grossTime$, (grossTime) => {
            const vehicles = data.flatMap((item) => {
                if (!item.vehicles.length) {
                    return [];
                }
                return [
                    {
                        activities: [],
                        trips: [],
                        availabilityStatuses: [],
                    },
                    ...item.vehicles,
                ];
            });
            const result = vehicles.map((vehicle: IPlanboardVehicleView) =>
                CanvasDataItem.combine(
                    CanvasDataItem.fromBusTrip(
                        vehicle,
                        !!grossTime,
                        CanvasPipedata.clickHandlers.get(ClickHandlerType.Trip),
                        CanvasPipedata.isMdtPlaning,
                    ),
                    CanvasDataItem.fromBusActivities(
                        vehicle,
                        CanvasPipedata.clickHandlers.get(
                            ClickHandlerType.Status,
                        ),
                    ),
                    CanvasDataItem.fromBusAvailabilityStatuses(
                        vehicle,
                        CanvasPipedata.dateRange,
                    ),
                ),
            );
            return result;
        });
    }
}
