import { OverlappingTrip } from "./../../driver-hours/days-off/shared/days-off.model";
import {
    ApplicationRef,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    EventEmitter,
    Injector,
    NgZone,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import moment from "moment";
import {
    BehaviorSubject,
    combineLatest,
    EMPTY,
    forkJoin,
    Observable,
    of,
    Subject,
    Subscription,
    throwError,
} from "rxjs";
import {
    catchError,
    concatMap,
    debounceTime,
    distinctUntilChanged,
    filter,
    finalize,
    map,
    mergeMap,
    startWith,
    switchMap,
    take,
    skip,
    takeUntil,
    tap,
    throttleTime,
    pairwise,
} from "rxjs/operators";
import { ToasterNotificationService } from "src/app/shared/components/toaster-notification/toaster-notification.service";
import { I18nPipe } from "src/app/shared/i18n/i18n.pipe";
import { MouseEventsService } from "src/app/shared/services/mouse-events.service";
import { ModalWindowService } from "../../shared/components/modal-window/modal-window.service";
import { AutoUnsubscribe } from "../../shared/decorators/auto-unsubscribe";
import { CanvasClickEventArgs } from "../canvas/canvas-click-event-args.model";
import { DateRange } from "../canvas/models/date-range.model";
import { ACTIVE_TAB } from "../common/const/active-tab.const";
import { TRIP_ASSIGNEMENT_VALUE } from "../common/const/trip-assignement.const";
import { BusAvailabilitySelectionComponent } from "../components/busSelectionComponent/busAvailabilitySelection.component";
import { DragAndDropEventTypes } from "../components/dnd";
import { DragAndDropService } from "../components/dnd/drag-and-drop.service";
import { TripCardService } from "../components/trip-card/trip-card.service";
import { DriversPopupComponent } from "../drivers/drivers-popup/drivers-popup.component";
import { IBusStatus } from "../drivers/models/driver-general.interface";
import { PlanningSynchronizationService } from "../planning-synchronization.service";
import { PlanningService } from "../planning.service";
import { ScaleConfigurationBaseComponent } from "../components/scale-base-configuration/scale-configuration-base.component";
import { ITrip } from "../trips/models/trip.model";
import { TripsHolderJson } from "../trips/models/tripsHolder.json";
import { IDraggable } from "../trips/models/tripsHolder.model";
import {
    ISeatConfigurationOptions,
    LEGEND_QUERY_PARAM_NAME,
    SEAT_CONFIGURATION_SHORTCUTS_MAP,
} from "./const/const";
import {
    IBus,
    IBusesFilter,
    IPlanboardVehicleView,
    PlanboardVehicle,
    VehicleType,
    VehicleTypes,
} from "./models/vehicle.model";
import { ClickHandlerType } from "./pipes/canvas.pipe";
import { BusService } from "./services/api.service";
import {
    CustomSeats,
    IBusAssign,
    IPlanboardVehicleDataView,
} from "../models/planboard-data.model";
import { IPlanboardTrip } from "../models/planboard-trip.model";
import { TabConfiguration } from "../../shared/components/tabs-container/tab-configuration";
import { IVehicleAvailability } from "./models/vehicle-availability.model";
import { VehicleAvailabilityStatusesEnum } from "./models/vehicle-availability-statuses.enum";
import { calcStatuses } from "../common/functions/helper";
import {
    CanvasEventService,
    DropEvent,
    ScrollDirection,
} from "../canvas/services/canvas-event.service";
import { CanvasContextNames } from "../canvas/const/context-names.enum";
import { IModalOpenOptions } from "../../shared/components/modal-window/modal-options.interface";
import { BusCardService } from "../components/bus-card/bus-card.service";
import { DriverCardService } from "../components/driver-card/driver-card.service";
import { ActionsLoaderService } from "src/app/actions/services/actions-loader.service";
import { BusFilterSettingsStore } from "./store/filter-settings-store";
import { BusFiltersConfigurationProvider } from "./providers/filters-configuration.provider";
import { OverlapAssignmentConfirmationService } from "../../shared/services/overlap-assignment-confirmation.service";
import { ActivityModalActions } from "./models/planboard-activity-modal-actions.model";
import { BusStatusService } from "../components/busSelectionComponent/busStatus.service";
import { AssignNotificationService } from "../common/services/assign-notification.service";
import * as _ from "lodash";
import { TripsService } from "../../trips/services/trips.service";
import { PointsService } from "./services/points.service";
import { BusTabMode } from "./models/bus-tab-mode.model";
import { StorageService } from "src/app/services/storage.service";
import { MannualTripCardComponent } from "../components/trip-card/mannual-trip-card/mannual-trip-card.component";
import { MannualReturnTripDialogComponent } from "../components/mannual-return-trip-dialog/mannual-return-trip-dialog.component";
import { MannualTripNew } from "../components/trip-card/models/mannual-trip.model";
import { IAsigneeDriver } from "../components/assignment-card/assignment-driver-filter.model";
import { rowHighlightcolors } from "../types/planboard-row-highlight-colors.const";
import { OverlapAssignmentConfirmationComponent } from "../../shared/components/overlap-driver-assignment-confirmation/overlap-assignment-confirmation.component";
import { clearSelectionBoundaries } from "../planning-helpers";
import {
    DEFAULT_PLANBOARD_MODE,
    DEFAULT_VEHICLE_DATA_SHOW,
    isGrossType,
    SwitchToggleConfigOption,
    SwitchToggleConfig,
    PLANBOARD_DEFAULT_TOGGLE_CONFIG,
    PLANBOARD_BUSES_TAB_USER_SETTINGS_KEY,
    TAB_KEYS,
} from "../common/const/const";
import { SeatsService } from "./services/set-seats.service";
import { MOUSE_OVER_DEBOUNCE_TIME } from "../canvas/canvas.component";
import { UserSettingsService } from "../../services/user-settings.service";
import { SeatOptions } from "./models/seat.model";
import { WarningTrailerPopupComponent } from "../../shared/components/warning-trailer-popup/warning-trailer-popup.component";
import { ITripCardData } from "../components/trip-card/models/trip-card-data.interface";
import { safeDetectChanges } from "src/app/shared/common-functions";

import { CarFilterPipe } from "./pipes/carsFilter.pipe";
import { VehicleAvailabilityPipe } from "./pipes/vehicle-availability.pipe";
import { RowIndexPipe } from "./pipes/row-index.pipe";
import { RenderablePipe } from "./pipes/canvas.pipe";
import { BusFilterPipe } from "./pipes/filter.pipe";
import { ICanvasDataItem } from "../canvas/models/canvas-configuration.interface";
import { SplittedTypes } from "../models/splitted-types.model";
import { OrderType } from "../canvas/const/order-type.enum";
import { TripDateFilterPipe } from "./pipes/trip-date-filter.pipe";
import { DismissData } from "./models/canvas-modal-dismiss.model";
import { PlanningBoardRendererService } from "../canvas/services/planning-board-render.service";
import { IDriver } from "../drivers/models/driver.model";
import { GarageTypes } from "../components/trip-card/enums/garage-point.enum";
import { ErrorCodes } from "../../shared/enums/soft-constraint-codes.enum";

const enum ToggleDurationTimeType {
    GROSS = "Gross",
    NET = "Net",
} // TODO: can be changed to computed(TS version = 5), but angular-ts compatibility.
enum ToggleAvailabilityStatus {
    AVAILABLE = "Available",
    ALL = "All",
}
enum ToggleVehicleType {
    CARS = "Cars",
    BUSES = "Buses",
}
enum ToggleCreationMode {
    ACTIVITY = "Activity",
    TRIP = "Trip",
}
enum OrderAttributes {
    TRAILER = "trailer",
    WHEELCHAIR = "wheelchair",
}
enum ASSIGN_ACTION {
    ASSIGN,
    REASSIGN,
}
interface UserPlanboardBusesTabSwitchersSettings {
    durationTimeType: ToggleDurationTimeType;
    availabilityStatus: ToggleAvailabilityStatus;
    vehicleType: ToggleVehicleType;
    creationMode: ToggleCreationMode;
}

const SHOW_CARS_KEY = "SHOW_CARS";
const HELLOBUS_LABEL = "HelloBus";
const BLOCKING_VALIDATION = "BLOCKING_VALIDATION";
const DEFAULT_SCALE_CONFIGURATION = "default";

@Component({
    selector: "ge-planning-buses",
    templateUrl: "./buses.component.html",
    styleUrl: "./buses.component.scss",
    providers: [
        I18nPipe,
        OverlapAssignmentConfirmationService,
        BusStatusService,
        AssignNotificationService,
    ],
})
@AutoUnsubscribe()
export class PlanningBusesComponent
    extends ScaleConfigurationBaseComponent
    implements OnInit, OnDestroy
{
    @ViewChild("scrollContainer", { read: ElementRef, static: false })
    set content(content: any) {
        if (content) {
            content.nativeElement.scrollTop = this.scrollTop;
        }
        this.scrollContainer = content;
    }

    get loading() {
        if (this.rendererService.bussesLoaderWasInitiated) {
            return (
                this.rendererService.bussesLoaderState ||
                this.rendererService.tripsLoaderState
            );
        } else {
            return this.rendererService.bussesLoaderState;
        }
    }

    set loading(value: boolean) {
        if (value) {
            this.rendererService.bussesLoaderWasInitiated = true;
        }
        this.rendererService.bussesLoaderState = value;
    }

    /*
     * Add takeUntil pipe with destroy$ Subject from parent component to all subscriptions.
     * This is need to avoid memory leaks.
     * */

    tabsConfiguration: TabConfiguration[] = [
        {
            linkUrl: "trips",
            i18nTitle: "Trips.tripsHeader.title",
        },
    ];

    public showPlanning$ = this.activatedRoute.queryParams.pipe(
        takeUntil(this.destroy$),
        map((params) => params[LEGEND_QUERY_PARAM_NAME]),
    );

    public vehicleType: typeof VehicleTypes = VehicleTypes;
    vehicleToShow$ = new BehaviorSubject<string>(DEFAULT_VEHICLE_DATA_SHOW);
    selectedPlanboardMode = DEFAULT_PLANBOARD_MODE;
    isOnlyAvailable$ = new BehaviorSubject<boolean>(false);

    showTripDetail = false;
    showBusDetail = false;

    tripsHolders: TripsHolderJson[] = [];

    currentBus: IPlanboardVehicleView;
    currentTrip: IDraggable;
    busToShow: any;

    buses$: Observable<IBus[]> = of([]);
    statuses$ = new BehaviorSubject<IBusStatus[]>([]);
    trips$ = new BehaviorSubject<ITrip[]>([]);
    grossTime$ = new BehaviorSubject<boolean>(true);
    loader$: Observable<boolean> = this.loaderService.loading$;

    currentPeriod: DateRange;

    buses: IBus[] = [];
    trips: IPlanboardTrip[] = [];
    isActionsDisabled = false;

    draggable: IDraggable;
    droppable: IPlanboardVehicleView;

    numberOfAssignedTrips = 0;
    numberOfTrips = 0;
    busesLoaded = false;
    isMdtPlaning = false;

    subscriptions$: Subscription = new Subscription();
    public vehicles$: Observable<PlanboardVehicle[]>;
    public planBoardData$: BehaviorSubject<IPlanboardVehicleDataView[]> =
        new BehaviorSubject<IPlanboardVehicleDataView[]>([]);
    vehicles: IPlanboardVehicleView[];
    scrollTop = 0;
    displayedTripStatuses: number;
    isGrossTimeOn = true;
    showCars = false;
    busesFilter: IBusesFilter;
    selectedMode: BusTabMode = BusTabMode.Activity;
    selectedBusIndex: number | string; // mouse over (hover)
    selectedBusClassName: string;
    canvasContextNames = CanvasContextNames;
    stopFetchingData$: Subject<never> = new Subject<never>();
    selectedRowIndex: number;
    startCurrentDate: Date;
    tripsOnCurrentDay: IPlanboardTrip[];
    endCurrentDate: Date;

    isScrolling: EventEmitter<boolean> = new EventEmitter<boolean>();
    scrollingTimeOut: any;
    isTripsTabExpanded = false;
    isTripsTabCollapsed = false;
    scrollContainer: ElementRef;

    isDragging = false;
    cachedTripHolderId: number = null;
    cachedTripId: number = null;

    driverToShow: IDriver;
    isDriverInfoShown = false;

    @ViewChild("viewContainerRef", { read: ViewContainerRef })
    VCR: ViewContainerRef;

    public componentReference: ComponentRef<DriversPopupComponent>;

    public openTripCard = (trip: IPlanboardTrip) => {
        if (!trip.orderType) {
            this.tripCardService.setTripCardDataSubject({
                currentTripHolderId: null,
                currentTripId: null,
                currentMannualTripId: trip.tripId,
                userOpen: true,
            });
            return;
        }
        const { tripId, tripHolderId } = trip;
        this.tripCardService.setTripCardDataSubject({
            currentTripHolderId: tripHolderId,
            currentTripId: tripId,
            type: "buses",
            userOpen: true,
        });
    };

    public openAvailabilitySelection = (
        args: CanvasClickEventArgs | any,
        data: any = {},
    ) => {
        // If this is garage line
        if (!args.renderableItemId && !args.vehicleId && args.data.isRental) {
            console.log("#return-bus-2");
            return;
        }

        const busId: number = args.vehicleId || args.renderableItemId;
        const { garageId } = data;

        this.busService
            .fetchAdoptedBusById(
                busId,
                this.scaleConfiguration.dateRange.from,
                this.scaleConfiguration.dateRange.to,
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe((bus: IBus) => {
                if (this.container) {
                    this.container.clear();
                }

                const cnt: ElementRef = this.container.element;

                const componentFactory =
                    this.componentFactoryResolver.resolveComponentFactory(
                        BusAvailabilitySelectionComponent,
                    );
                const newComponentRef =
                    this.container.createComponent(componentFactory);

                const busAvailabilitySelectionComponent =
                    newComponentRef.instance;

                busAvailabilitySelectionComponent.bus = bus;

                busAvailabilitySelectionComponent.configuration.garageId =
                    garageId;

                if (args.coordinates && args.date && args.renderableItemId) {
                    busAvailabilitySelectionComponent.configuration.busId =
                        args.renderableItemId;
                    busAvailabilitySelectionComponent.configuration.from =
                        args.date;
                } else if (args.vehicleId && args.status) {
                    busAvailabilitySelectionComponent.configuration.from =
                        args.startDateTime;
                    busAvailabilitySelectionComponent.configuration.to =
                        args.endDateTime;
                    busAvailabilitySelectionComponent.configuration.description =
                        args.description;
                    busAvailabilitySelectionComponent.configuration.status =
                        args.status;
                    busAvailabilitySelectionComponent.configuration.busId =
                        args.vehicleId;
                    busAvailabilitySelectionComponent.configuration.statusId =
                        args.activityId;
                    busAvailabilitySelectionComponent.configuration.isNewStatus =
                        false;
                }

                busAvailabilitySelectionComponent.dismiss
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(({ action, payload }) => {
                        clearSelectionBoundaries();
                        if (action !== ActivityModalActions.Close) {
                            this.loading = true;
                        }

                        let actionRequest: Observable<any> = null;

                        switch (action) {
                            case ActivityModalActions.Add:
                                actionRequest = this.addStatus(
                                    payload.addStatusPayload,
                                );
                                break;
                            case ActivityModalActions.Update:
                                actionRequest = this.updateStatus(
                                    payload.statusId,
                                    payload.payload,
                                );
                                break;
                            case ActivityModalActions.Delete:
                                actionRequest = this.deleteStatus(
                                    payload.statusId,
                                    bus.id,
                                );
                                break;
                        }

                        if (actionRequest) {
                            actionRequest
                                .pipe(
                                    tap(() => this.fetchData()),
                                    takeUntil(this.destroy$),
                                )
                                .subscribe();
                        }

                        newComponentRef.destroy();
                        this.canvasEventService.selectRowByTrip$.next({
                            index: null,
                            contextName: CanvasContextNames.busses,
                        });
                    });
            });
    };

    public clickHandlers: Map<ClickHandlerType, (...args: any) => void> =
        new Map<ClickHandlerType, (...args: any) => void>([
            [ClickHandlerType.Trip, this.openTripCard],
            [ClickHandlerType.Status, this.openAvailabilitySelection],
        ]);

    protected vehicleTypeToggleConfig: SwitchToggleConfig = _.cloneDeep(
        PLANBOARD_DEFAULT_TOGGLE_CONFIG.BUSES_VEHICLE_TYPE,
    ) as SwitchToggleConfig;
    protected durationTypeToggleConfig: SwitchToggleConfig = _.cloneDeep(
        PLANBOARD_DEFAULT_TOGGLE_CONFIG.BUSES_DURATION_TIME_TYPE,
    ) as SwitchToggleConfig;
    protected creationModeToggleConfig: SwitchToggleConfig = _.cloneDeep(
        PLANBOARD_DEFAULT_TOGGLE_CONFIG.BUSES_CREATION_MODE,
    ) as SwitchToggleConfig;
    protected driverVehicleAvailabilityStatusTypeToggleConfig: SwitchToggleConfig =
        _.cloneDeep(
            PLANBOARD_DEFAULT_TOGGLE_CONFIG.BUSES_AVAILABILITY_STATUS_TYPE,
        ) as SwitchToggleConfig;
    protected canvasData: ICanvasDataItem[];
    protected transformedPlanboardData: IPlanboardVehicleDataView[];
    private mutationObserver: MutationObserver;
    private stopLoading$: EventEmitter<void> = new EventEmitter<void>();
    private userPlanboardBusesTabSwitchersSettings: UserPlanboardBusesTabSwitchersSettings;
    private highlightedIndex$ = new Subject<number>();

    @ViewChild("container", { read: ViewContainerRef, static: false })
    private container: ViewContainerRef;
    // private container: ViewContainerRef;
    private channel = new BroadcastChannel("trip-overview");
    private dividedPassengers: number;
    private _getVehiclesSubscription$: Subscription = null;
    private get isShuttleOrTransvision(): boolean {
        return [OrderType.Shuttle, OrderType.Transvision].includes(
            this.currentTrip.orderType as OrderType,
        );
    }

    constructor(
        injector: Injector,
        private app: ApplicationRef,
        private planningService: PlanningService,
        private busService: BusService,
        private pss: PlanningSynchronizationService,
        public toasterNotificationService: ToasterNotificationService,
        private i18n: I18nPipe,
        private dragAndDropService: DragAndDropService,
        private tripCardService: TripCardService,
        private tripsService: TripsService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private changeDetectorRef: ChangeDetectorRef,
        private mouseEventsService: MouseEventsService,
        private activatedRoute: ActivatedRoute,
        private modalService: ModalWindowService,
        private busCardService: BusCardService,
        private canvasEventService: CanvasEventService,
        private driverCardService: DriverCardService,
        private loaderService: ActionsLoaderService,
        private filterStore: BusFilterSettingsStore,
        private busFiltersConfigurationProvider: BusFiltersConfigurationProvider,
        private overlapAssignmentService: OverlapAssignmentConfirmationService,
        private pointsService: PointsService,
        private busStatusService: BusStatusService,
        private assignNotificationService: AssignNotificationService,
        private zone: NgZone,
        private storage: StorageService,
        private seatsService: SeatsService,
        private userSettingsService: UserSettingsService,
        private carFilterPipe: CarFilterPipe,
        private filterPipe: BusFilterPipe,
        private tripDateFilterPipe: TripDateFilterPipe,
        private vehicleAvailabilityPipe: VehicleAvailabilityPipe,
        private rowIndexPipe: RowIndexPipe,
        private canvasPipe: RenderablePipe,
        private rendererService: PlanningBoardRendererService,
    ) {
        super(injector);

        this.highlightedIndex$
            .pipe(distinctUntilChanged())
            .subscribe((index: number) => {
                this.selectedRowIndex = index;
                this.canvasEventService.highlightRowByIndex.emit({
                    index,
                    contextName: CanvasContextNames.busses,
                });
            });

        this.canvasEventService.selectItemRowByIndex$
            .pipe(
                filter(
                    (event) => event.contextName === CanvasContextNames.busses,
                ),
                takeUntil(this.destroy$),
            )
            .subscribe((event) => {
                if (!this.isDragging) {
                    this.highlightedIndex$.next(event.index);
                }
            });

        this.canvasEventService.onDrop$
            .pipe(
                takeUntil(this.destroy$),
                tap((event) => {
                    this.isDragging = false;
                    this.selectedBusIndex = null;
                }),
            )
            .subscribe((event) => {
                if (!event || !event.target || !event.source) {
                    return;
                }
                this.onCanvasDropHandler(event);
            });

        this.canvasEventService.onDrag$
            .pipe(
                takeUntil(this.destroy$),
                tap(() => {
                    this.isDragging = true;
                }),
            )
            .subscribe(this.onCanvasDragHandler.bind(this));

        this.canvasEventService.onScroll$
            .pipe(throttleTime(110), takeUntil(this.destroy$))
            .subscribe(this.onCanvasScroll.bind(this));

        this.canvasEventService.selectRowByTrip$
            .pipe(takeUntil(this.destroy$))
            .subscribe((event) => {
                this.highlightedIndex$.next(
                    event.contextName === CanvasContextNames.busses
                        ? event.index
                        : null,
                );
            });
        this.tripCardService
            .getTripCardDataSubject()
            .pipe(skip(1), takeUntil(this.destroy$))
            .subscribe((res: ITripCardData) => {
                const {
                    currentMannualTripId,
                    currentTripHolderId,
                    currentTripId,
                    userOpen,
                    editFromTripCard,
                } = res;
                if (editFromTripCard) {
                    return;
                }
                // If card is closed, but user changing trip by assign/reassign/etc
                if (!userOpen && !this.showTripDetail) {
                    return;
                }

                // If trip card is opened and user clicks second time on that trip
                if (userOpen && this.showTripDetail) {
                    return;
                }
                const tripCardOpened: boolean =
                    !currentMannualTripId &&
                    this.cachedTripHolderId === +currentTripHolderId;
                const mannualTripCardOpened: boolean =
                    currentMannualTripId &&
                    currentMannualTripId === this.cachedTripId;

                if (
                    (tripCardOpened || mannualTripCardOpened) &&
                    this.showTripDetail
                ) {
                    clearSelectionBoundaries();
                } else if (
                    currentTripId ||
                    currentTripHolderId ||
                    currentMannualTripId
                ) {
                    this.showTripDetail = true;
                    this.cachedTripHolderId = +currentTripHolderId;
                    this.cachedTripId = +currentMannualTripId || +currentTripId;
                }

                this.closeBusInfo();
            });
    }

    ngOnInit(): void {
        this.subscribeToUserSettings();
        this.subscribeToFilterChanges();
        this.pipeShowPlanning();
        this.subscribeToVariousServices();
        this.subscribeToStopLoading();
        this.handleBroadcastChannel();
        this.subscribeToPlanBoardData();
    }

    handleExpandTripsTab(isExpanded: boolean): void {
        this.isTripsTabExpanded = isExpanded;
        this.changeDetectorRef.detectChanges();
    }

    handleCollapseTripsTab(isCollapsed: boolean): void {
        this.isTripsTabCollapsed = isCollapsed;
        this.changeDetectorRef.detectChanges();
    }

    onScroll($event: any) {
        if (!this.scrollingTimeOut) {
            this.isScrolling.emit(true);
        }

        if (this.scrollingTimeOut) {
            clearTimeout(this.scrollingTimeOut);
            this.scrollingTimeOut = null;
        }

        this.scrollingTimeOut = setTimeout(() => {
            this.isScrolling.emit(false);
            clearTimeout(this.scrollingTimeOut);
            this.scrollingTimeOut = null;
        }, 350);

        $event.stopImmediatePropagation();

        this.scrollTop = $event.target.scrollTop;
    }

    setScrollPosition(top: number = this.scrollTop) {
        if (!this.scrollContainer) {
            return;
        }
        this.scrollContainer.nativeElement.scrollTop = top;
    }

    resetScrollPosition() {
        this.scrollTop = 0;
    }

    configureTogglesByUserSettings(
        userSettingsTabConfig: Record<string, any>,
    ): void {
        if (!userSettingsTabConfig) {
            return;
        }

        if (!userSettingsTabConfig.vehicleType) {
            userSettingsTabConfig.vehicleType = TAB_KEYS.BUS;
        }

        if (userSettingsTabConfig.vehicleType) {
            this.vehicleTypeToggleConfig.options.forEach(
                (item) => (item.selected = false),
            );

            const itemIndex = this.vehicleTypeToggleConfig.options.findIndex(
                (item) => item.value === userSettingsTabConfig.vehicleType,
            );

            if (itemIndex === -1) {
                console.error(
                    `Didn't find option with value ${userSettingsTabConfig.vehicleType}`,
                );
                return;
            }

            this.emitVehicleTypeChangesProcessing(
                userSettingsTabConfig.vehicleType,
            );
            this.vehicleTypeToggleConfig.options[itemIndex].selected = true;
        }

        if (userSettingsTabConfig.creationMode) {
            this.creationModeToggleConfig.options.forEach(
                (item) => (item.selected = false),
            );

            const itemIndex = this.creationModeToggleConfig.options.findIndex(
                (item) => item.value === userSettingsTabConfig.creationMode,
            );

            if (itemIndex === -1) {
                console.error(
                    `Didn't find option with value ${userSettingsTabConfig.creationMode}`,
                );
                return;
            }

            this.emitCreationModeChangedProcessing(
                userSettingsTabConfig.creationMode,
            );
            this.creationModeToggleConfig.options[itemIndex].selected = true;
        }

        if (userSettingsTabConfig.durationTimeType) {
            this.durationTypeToggleConfig.options.forEach(
                (item) => (item.selected = false),
            );

            const itemIndex = this.durationTypeToggleConfig.options.findIndex(
                (item) => item.value === userSettingsTabConfig.durationTimeType,
            );

            if (itemIndex === -1) {
                console.error(
                    `Didn't find option with value ${userSettingsTabConfig.durationTimeType}`,
                );
                return;
            }

            this.emitDurationTimeTypeChangesProcessing(
                userSettingsTabConfig.durationTimeType,
            );
            this.durationTypeToggleConfig.options[itemIndex].selected = true;
        }

        if (userSettingsTabConfig.availabilityStatus) {
            this.driverVehicleAvailabilityStatusTypeToggleConfig.options.forEach(
                (item) => (item.selected = false),
            );

            const itemIndex =
                this.driverVehicleAvailabilityStatusTypeToggleConfig.options.findIndex(
                    (item) =>
                        item.value === userSettingsTabConfig.availabilityStatus,
                );

            if (itemIndex === -1) {
                console.error(
                    `Didn't find option with value ${userSettingsTabConfig.availabilityStatus}`,
                );
                return;
            }

            this.emitAvailabilityStatusChangedProcessing(
                userSettingsTabConfig.availabilityStatus,
            );
            this.driverVehicleAvailabilityStatusTypeToggleConfig.options[
                itemIndex
            ].selected = true;
        }
    }

    isTripsStartOnThisDate(trips: IPlanboardTrip[]): void {
        const startDate = moment(this.startCurrentDate);
        const endDate = moment(this.endCurrentDate);
        if (trips && trips.length) {
            this.tripsOnCurrentDay = trips.reduce((acc, trip) => {
                if (trip === undefined) {
                    return acc;
                }

                const tripStartDate = moment(trip.startDateTime);
                if (tripStartDate.isBetween(startDate, endDate)) {
                    acc.push(trip);
                }
                return acc;
            }, []);
        }
    }

    fetchData() {
        if (this._getVehiclesSubscription$) {
            this._getVehiclesSubscription$.unsubscribe();
            this._getVehiclesSubscription$ = null;
        }

        this.loading = true;
        this._getVehiclesSubscription$ = new Subscription();
        const subscription = this.busService
            .getPlanboardVehicleData(this.busesFilter)
            .pipe(
                tap((data) => {
                    this.planBoardData$.next(data);
                    const vehicles = data
                        .flatMap((item) => [{}, ...item.vehicles]) // Editing this - can impact on highlighting which extra-index based on this structure
                        .map((vehicle: any, index) => {
                            vehicle.index = index;
                            return vehicle;
                        })
                        .filter((vehicle) => vehicle.id);

                    this.vehicles = vehicles;
                    this.trips = vehicles.flatMap((vehicle) => vehicle.trips);

                    if (!this.mutationObserver) {
                        this.mutationObserver = new MutationObserver(() => {
                            this.stopLoading$.emit();
                        });

                        const svg =
                            this.container.element.nativeElement.childNodes[0]
                                .childNodes[0];
                        this.mutationObserver.observe(svg, {
                            attributes: false,
                            childList: true,
                            characterData: false,
                            subtree: true,
                        });
                    }

                    setTimeout(() => safeDetectChanges(this.changeDetectorRef));
                    this.setScrollPosition();
                    clearSelectionBoundaries();
                }),
                takeUntil(this.destroy$),
            )
            .subscribe();
        this._getVehiclesSubscription$.add(subscription);
        this.busCardService.activityStatusChanged$.next(false);
    }

    public isVehicleOfType(
        vehicle: IPlanboardVehicleView,
        vehicleType: VehicleTypes,
    ): boolean {
        return vehicle.__typename === vehicleType;
    }

    ngOnDestroy(): void {
        // This is need to avoid memory leaks
        this.subscriptions$.unsubscribe();
        this.planBoardData$.unsubscribe();
        this.channel.close();
        this.showPlanning$ = null;
        this.scrollContainer = null;
        this.container = null;
        super.ngOnDestroy();
    }

    loadBuses = (): void => {
        this.buses$ = this.busService
            .fetchBuses(this.scaleConfiguration.dateRange)
            .pipe(
                tap((buses: IBus[]) => {
                    this.buses = buses;
                }),
            );
    };

    loadStatuses = (): void => {
        const fetchBusStatuses$ = this.busService
            .fetchBusStatuses({
                from: this.scaleConfiguration.dateRange.from.toUTCString(),
                to: this.scaleConfiguration.dateRange.to.toUTCString(),
            })
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.statuses$.next(res);
            });

        this.subscriptions$.add(fetchBusStatuses$);
    };

    public openCreateModal = (args: any) => {
        if (args.data.isLabel) {
            return;
        }

        if (args.data.data.garageId === null) {
            console.log("#return-bus-1");
            return;
        }
        if (this.selectedPlanboardMode === DEFAULT_PLANBOARD_MODE) {
            this.openAvailabilitySelection(args, args.data.data);
        } else {
            this.openMannualTripCreate(args);
        }
    };

    addStatus(payload) {
        return this.busStatusService.createVehicleStateExclusion(payload).pipe(
            catchError((error) => {
                this.stopLoading$.emit();
                this.overlapAssignmentService
                    .processErrors(error)
                    .pipe(take(1), takeUntil(this.destroy$))
                    .subscribe();

                return EMPTY;
            }),
        );
    }

    addSeatingArrangement(payload) {
        return this.busStatusService.addSeatingArrangment(payload).pipe(
            catchError(() => {
                this.stopLoading$.emit();
                this.toasterNotificationService.setToaster({
                    toasterType: "error",
                    toasterTitle: `Can't add seating arrangement`,
                    toasterText: `Can't add seating arrangement`,
                });
                return EMPTY;
            }),
        );
    }

    editSeatingArrangement(deletePayload, addPayload) {
        const deleteRequest$ =
            this.busStatusService.removeSeatingArrangement(deletePayload);
        const addRequest$ =
            this.busStatusService.addSeatingArrangment(addPayload);

        return deleteRequest$.pipe(
            take(1),
            concatMap(() => addRequest$),
            catchError(() => {
                this.toasterNotificationService.setToaster({
                    toasterType: "error",
                    toasterTitle: `Can't edit seating arrangement`,
                    toasterText: `Can't edit seating arrangement`,
                });
                return EMPTY;
            }),
        );
    }

    updateStatus(statusId, payload) {
        return this.busStatusService
            .updateVehicleStateExclusion(statusId, payload)
            .pipe(
                catchError((error) => {
                    this.stopLoading$.emit();
                    this.overlapAssignmentService
                        .processErrors(error)
                        .pipe(take(1), takeUntil(this.destroy$))
                        .subscribe();
                    return EMPTY;
                }),
            );
    }

    removeSeatingArrangement(payload) {
        return this.busStatusService.removeSeatingArrangement(payload).pipe(
            catchError(() => {
                this.toasterNotificationService.setToaster({
                    toasterType: "error",
                    toasterTitle: `Can't remove seating arrangement`,
                    toasterText: `Can't remove seating arrangement`,
                });
                return EMPTY;
            }),
        );
    }

    deleteStatus(statusId, busId) {
        return this.busStatusService.deleteBusStatusById(statusId, busId).pipe(
            catchError(() => {
                this.stopLoading$.emit();
                this.toasterNotificationService.setToaster({
                    toasterType: "error",
                    toasterTitle: `Can't update status`,
                    toasterText: `Can't update status`,
                });
                return EMPTY;
            }),
        );
    }

    updateGrossTimeSelection(flag: boolean): void {
        this.grossTime$.next(flag);
        this.pss.grossTimeChanged$.next(flag);
        this.isGrossTimeOn = flag;
    }

    modeChanged(mode: BusTabMode) {
        this.selectedMode = mode;
    }

    updateCarsSelection(flag: boolean): void {
        this.storage.setItem(SHOW_CARS_KEY, flag.toString());
        this.showCars = flag;
    }

    cantAssignNotification(trip: IDraggable): void {
        let tripId: number;
        trip.tripId ? (tripId = trip.tripId) : (tripId = trip.tripsHolderId);
        this.toasterNotificationService.setToaster({
            toasterType: "error",
            toasterTitle: this.i18n.transform("busAssignment.busIsNotAssigned"),
            toasterText: `Bus ${this.currentBus.number} ${this.i18n.transform("busAssignment.busNotSuccessfullyAssigned")} ${tripId}`,
        });
    }

    onDrop(trip: IDraggable, vehicle: any, alreadyAssign?: boolean): void {
        this.draggable = null;
        this.currentTrip = trip;
        this.currentBus = vehicle;
        this.dividedPassengers = null;

        const tripDate = {
            from: trip.startDateTime,
            to: trip.endDateTime,
        };

        const options: IModalOpenOptions = {
            title: null,

            ngbOptions: {
                centered: true,
            },
        };
        if (alreadyAssign && trip.assignedBusId === vehicle.busId) {
            return;
        }
        const showNitification = () =>
            this.showNotificationOnAssign(
                trip.tripsHolderId,
                vehicle.id,
                trip.tripId,
            );
        const vehicleIsCar = this.currentBus.vehicleType === VehicleType.Car;
        const showCarAssignmentWarming = () =>
            this.zone.run(() => {
                this.loading = false;
                this.modalService
                    .open(OverlapAssignmentConfirmationComponent, {
                        ...options,
                        data: { errorCode: ErrorCodes.ASSIGN_CAR_ON_TRIP },
                    })
                    .subscribe((isAccepted) => {
                        if (isAccepted) {
                            if (!alreadyAssign) {
                                this.assignBusToTrip(
                                    trip.tripsHolderId,
                                    vehicle.id,
                                    trip.tripId,
                                    false,
                                ).subscribe();
                            } else {
                                this.reassignBusOnATrip(vehicle, trip);
                            }
                        }
                        this.loading = false;
                        return;
                    });
            });

        if (
            vehicleIsCar &&
            trip &&
            _.isBoolean(trip["isCarTrip"]) &&
            !trip["isCarTrip"]
        ) {
            showCarAssignmentWarming();
        } else if (!alreadyAssign) {
            this.assignBus(vehicle, showNitification, tripDate, trip);
        } else {
            this.reassignBusOnATrip(vehicle, trip);
        }
    }

    public onCanvasDragHandler = (event) => {
        if (event) {
            const { callback, target, source } = event;
            const vehicle = this.vehicles.find(
                (v) => v.entityId === target.entityId,
            );
            this.selectedBusIndex = vehicle.index;
            const { payload } = source;
            const trip = {
                ...event.source,
                tripsHolderId: payload.tripHolderId,
                ...payload,
            };
            const className = event.source.payload.orderType
                ? this.getBackgroundClass(vehicle, trip)
                : this.getMannualTripBackgroundClass(vehicle, trip);
            this.selectedBusClassName = className;
            callback(className);
        } else {
            this.selectedBusIndex = null;
            this.selectedBusClassName = null;
        }
    };

    // Canvas drop handler
    onCanvasDropHandler(event: DropEvent) {
        this.isDragging = false;
        if (event.target.entityId !== 0 && !event.target.entityId) {
            return;
        }

        if (!event.target && !event.source) {
            this.selectedBusClassName = null;
            return;
        }
        const { orderType, vehicleGarage } = event.source.payload;
        if (!orderType && event.target.isRental) {
            return;
        }

        const vehicle = this.vehicles.find(
            (v) => v.entityId === event.target.entityId,
        );
        if (
            !orderType &&
            vehicleGarage.toLowerCase() !== vehicle.garageName.toLowerCase()
        ) {
            this.selectedBusClassName = rowHighlightcolors.nonColor;
            return;
        }

        const { payload } = event.source;
        const startFromGarage =
            payload.startFromGarage ||
            payload.startsFromGarage ||
            payload.isTripStartFromGarage;
        const endToGarage =
            payload.endToGarage ||
            payload.endsInGarage ||
            payload.isTripEndToGarage;
        const trip = {
            ...event.source,
            tripsHolderId: payload.tripHolderId,
            startFromGarage,
            endToGarage,
            ...payload,
        };
        const alreadyAssigned = !!trip.assignedBusId;
        this.currentTrip = trip;
        this.selectedBusIndex = null;
        this.selectedBusClassName = null;

        if (trip.assignedBusId === vehicle.id) {
            return;
        }
        const trips = event.source.payload.trips;
        const isManualReturnTrip =
            trip.payload && trip.payload.isReturnInternalTrip;
        if (
            isManualReturnTrip &&
            trip.isCarTrip &&
            trip.isTripStartFromGarage
        ) {
            return;
        }

        if (vehicle.trips.length && !event.target.isRental) {
            const isVehicleHasThisTrip = vehicle.trips.find((item) => {
                return (
                    (item.id && trip.id && item.id === trip.id) ||
                    item.tripId === trip.tripId
                );
            });
            if (isVehicleHasThisTrip) {
                return;
            }
        }

        this.onDrop(trip, vehicle, alreadyAssigned);
    }

    onDrag(
        trip: { data: IDraggable; el: any },
        vehicle: IPlanboardVehicleView,
    ): void {
        if (!trip.data) {
            return;
        }

        this.draggable = trip.data;
        this.droppable = vehicle;

        trip.el.classList.add(this.getBackgroundClass(vehicle, this.draggable));
    }

    onHideTripCard(refresh?: boolean): void {
        setTimeout(() => (this.showTripDetail = false));
        clearSelectionBoundaries();
    }

    onClick = (tripsHolderId: string, tripId?: number) => {
        this.tripCardService.setTripCardDataSubject({
            currentTripHolderId: parseInt(tripsHolderId, 10),
            currentTripId: tripId,
            type: "buses",
        });
        this.showTripDetail = true;
    };

    onAssignDivide(
        busComingFromGarage?: boolean,
        busReturnToGarage?: boolean,
    ): void {
        this.showNotificationOnAssign(
            this.currentTrip.tripsHolderId,
            this.currentBus.id,
            this.currentTrip.tripId,
            true,
            true,
            busComingFromGarage,
            busReturnToGarage,
        );
    }

    defineTripPassengersCount() {
        const seatConfig =
            this.currentTrip.seatingConfiguration ||
            this.currentTrip.currentSeatConfiguration;
        const seatConfigPassengers =
            seatConfig.passengers ||
            seatConfig.passengersWithoutSeats ||
            seatConfig.seats;
        const tripPassengersCount = this.currentTrip.passengers
            ? this.currentTrip.passengers
            : seatConfigPassengers ||
              this.currentTrip.payload.seatingConfiguration
                  .passengersWithoutSeats;

        return tripPassengersCount;
    }

    onDivideReassign(
        bus: IPlanboardVehicleView,
        trip: ITrip,
        busComingFromGarage?: boolean,
        busReturnToGarage?: boolean,
    ): void {
        const vehicleTripDatesSeatingType =
            this.busService.getVehicleTripDatesSeatingType(
                this.currentBus,
                this.currentTrip,
            );
        const busPassengersCount = vehicleTripDatesSeatingType.seats;
        this.tripCardService
            .divideTrip(this.currentTrip.tripId, this.currentBus.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.dividedPassengers = busPassengersCount;
                const skipNonBlockingValidation = true;

                if (skipNonBlockingValidation) {
                    this.loading = true;
                }
                this.reassignBusToTripRequest(
                    bus,
                    trip,
                    skipNonBlockingValidation,
                    null,
                    busComingFromGarage,
                    busReturnToGarage,
                )
                    .pipe(takeUntil(this.destroy$))
                    .subscribe();
                this.tripCardService.tripDataChanged.next();
            });
    }

    showNotificationOnAssign(
        tripHolderId: number,
        busId: number,
        tripId: number,
        isNeedToBeDivide?: boolean,
        skipNonBlockingValidation = false,
        busComingFromGarage?: boolean,
        busReturnToGarage?: boolean,
    ): void {
        const vehicleTripDatesSeatingType =
            this.busService.getVehicleTripDatesSeatingType(
                this.currentBus,
                this.currentTrip,
            );

        if (isNeedToBeDivide && vehicleTripDatesSeatingType) {
            const currentArrangement = this.currentBus.seatingArrangments.find(
                (arrangement) => {
                    return (
                        moment(arrangement.startOn).isSameOrBefore(
                            moment(this.currentTrip.startDateTime),
                        ) &&
                        moment(arrangement.endOn).isSameOrAfter(
                            moment(this.currentTrip.endDateTime),
                        )
                    );
                },
            );

            const busPassengersCount =
                (currentArrangement
                    ? currentArrangement.seats
                    : vehicleTripDatesSeatingType.seats) +
                this.busService.getBusRestSeats(
                    this.currentTrip,
                    this.currentBus,
                );

            this.loaderService.startLoading();
            // divide trip
            this.tripCardService
                .divideTrip(tripId, this.currentBus.id)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    this.dividedPassengers = busPassengersCount;

                    this.assignBusToTrip(
                        data[0].tripHolderId,
                        busId,
                        tripId,
                        skipNonBlockingValidation,
                        busComingFromGarage,
                        busReturnToGarage,
                    )
                        .pipe(takeUntil(this.destroy$))
                        .subscribe(() => {
                            this.tripCardService.tripDataChanged.next();
                        });
                });
        } else {
            this.assignBusToTrip(tripHolderId, busId, tripId)
                .pipe(takeUntil(this.destroy$))
                .subscribe();
        }
    }

    public clearFilters(): void {
        this.filterStore.setCurrentSettings([]);
        this.busFiltersConfigurationProvider.clearFilter();
    }

    stopLoading(): void {
        this.loading = false;
        this.changeDetectorRef.detectChanges();
    }

    startLoading(action: ASSIGN_ACTION): void {
        this.loading = true;
        this.tripCardService.tripCardLoaderHandler$.next(true);

        if (action === ASSIGN_ACTION.ASSIGN) {
            this.tripCardService.unplannedTripsLoaderHandler$.next(true);
        }
    }

    assignBusToTrip(
        tripHolderId: number,
        vehicleId: number,
        tripId?: number,
        skipNonBlockingValidation = false,
        busComingFromGarage = true,
        busReturnToGarage = true,
    ) {
        this.startLoading(ASSIGN_ACTION.ASSIGN);
        const data = {
            vehicleId,
            tripId,
            passengers: this.dividedPassengers || this.currentTrip.passengers,
            points: null,
            busComingFromGarage,
            busReturnToGarage,
        };

        const requestData: IBusAssign = {
            ...data,
            points: null,
            skipNonBlockingValidation,
        };

        const request = (action, busAssignData) => {
            return action.call(this.planningService, busAssignData).pipe(
                catchError((err) => {
                    this.overlapAssignmentService.isShuttle =
                        this.isShuttleOrTransvision;
                    this.stopLoadingOnMessageShow();
                    this.zone.run(() => {
                        this.overlapAssignmentService
                            .processErrors(err)
                            .pipe(take(1), takeUntil(this.destroy$))
                            .subscribe((result) => {
                                if (result) {
                                    const {
                                        assignDivide,
                                        busComingFromGarage,
                                        busReturnToGarage,
                                    } = result;

                                    if (assignDivide) {
                                        this.onAssignDivide(
                                            busComingFromGarage,
                                            busReturnToGarage,
                                        );
                                        return;
                                    }

                                    this.assignBusToTrip(
                                        tripHolderId,
                                        vehicleId,
                                        tripId,
                                        true,
                                        busComingFromGarage,
                                        busReturnToGarage,
                                    )
                                        .pipe(takeUntil(this.destroy$))
                                        .subscribe();
                                    return;
                                }
                            });
                    });
                    return throwError(err);
                }),
                tap((data: any) => {
                    const { tripId, tripNumber } = data;

                    this.dragAndDropService.emit(DragAndDropEventTypes.onDrop);
                    this.tripCardService.setTripCardDataSubject({
                        currentTripHolderId: tripNumber,
                        currentTripId: tripId,
                        type: "buses",
                    });
                    this.busCardService.activityStatusChanged$.next(true);
                    this.tripCardService.tripDataChanged.next(true); // unsychron, remove it - and on assign there will be duplicate(wouldn't remove from trips section
                    this.changeDetectorRef.detectChanges();

                    this.assignNotificationService.showNotificationOnBusAssign(
                        this.currentBus,
                        tripNumber,
                    );
                }),
                finalize(() => {
                    this.loading = true;
                }),
                takeUntil(this.destroy$),
            );
        };

        if (this.currentBus.isRental) {
            requestData.points = this.pointsService.prepareRentalVehiclePoints(
                this.currentTrip,
            );
            requestData.points = this.pointsService.preparePoints(
                requestData.points,
            );
            const defaultSeatConfig: CustomSeats = {
                count: this.currentTrip
                    ? this.currentTrip.passengersForTheTrip
                    : null,
                type: this.currentTrip
                    ? this.currentTrip.seatingTypeName
                    : null,
            };
            this.loading = false;
            this.changeDetectorRef.detectChanges();
            return this.seatsService
                .openBusSeatEdit({
                    ...defaultSeatConfig,
                    count: this.currentTrip.passengersForTheTrip,
                })
                .pipe(
                    filter(Boolean),
                    tap(() => (this.loading = true)),
                    mergeMap((seatsConfig: SeatOptions) => {
                        return request(
                            this.planningService.assignRentalVehicleToTrip,
                            {
                                ...requestData,
                                seatsAssignInfo: {
                                    seats: seatsConfig.count,
                                    seatsType: seatsConfig.type,
                                },
                            },
                        );
                    }),
                );
        }

        const trip = _.cloneDeep(this.currentTrip) as any;
        const orderId = trip.order ? trip.order.id : trip.orderId;
        if (trip.payload.startsFromGarage && !trip.payload.startFromGarage) {
            trip.payload.startFromGarage = trip.payload.startsFromGarage;
        }
        if (trip.payload.endsInGarage && !trip.payload.endToGarage) {
            trip.payload.endToGarage = trip.payload.endsInGarage;
        }
        trip.points[0].isGarage = trip.payload.startFromGarage;
        trip.points[trip.points.length - 1].isGarage = trip.payload.endToGarage;

        return this.planningService.fetchOrderAlternativeGarages(orderId).pipe(
            switchMap((data) => {
                trip.orderGarages = data.orderGarages;
                trip.orderRoute = data.orderRoute;

                const garageName = this.currentTrip.payload.garageName;
                let requestObservable;

                if (
                    !garageName ||
                    garageName.toLowerCase() === this.currentBus.garageName
                ) {
                    requestData.points = null;
                    requestData.orderGarages =
                        this.planningService.defineAlternativeGarages(trip);

                    requestObservable = request(
                        this.planningService.assignBusToTrip,
                        requestData,
                    );
                } else {
                    requestObservable = this.pointsService
                        .preparePointsToRequest(trip, this.currentBus)
                        .pipe(
                            mergeMap((data) => {
                                requestData.points = data.points;
                                requestData.orderGarages = data.orderGarages;
                                return request(
                                    this.planningService.assignBusToTrip,
                                    requestData,
                                );
                            }),
                        );
                }

                return requestObservable.pipe(
                    tap((data) => {
                        if (
                            trip.orderAttributes &&
                            trip.orderAttributes.trailer &&
                            this.currentBus.isTrailer
                        ) {
                            const modalOptions: IModalOpenOptions = {
                                data: {
                                    text: "busListPage.trailerWarningModal.text",
                                },
                                ngbOptions: { centered: true },
                            };
                            this.modalService.open(
                                WarningTrailerPopupComponent,
                                modalOptions,
                            );
                        }
                    }),
                );
            }),
        );
    }

    onMakeAllTripsDefinite(): void {
        const { from, to } = this.scaleConfiguration.dateRange;

        this.planningService
            .setDefinedRange(moment(from).format(), moment(to).format())
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.getTripsHolder();
                this.busCardService.activityStatusChanged$.next(true);
                this.toasterNotificationService.setToaster({
                    toasterType: "success",
                    toasterTitle: this.i18n.transform(
                        "orders.details.allTripsDefinite",
                    ),
                    toasterText: this.i18n.transform(
                        "orders.details.allTripsDefinite",
                    ),
                });
            });
    }

    getTripsHolderNew = (): Observable<TripsHolderJson[]> =>
        this.planningService.fetchTripsHolders({
            from: this.scaleConfiguration.dateRange.from.toUTCString(),
            to: this.scaleConfiguration.dateRange.to.toUTCString(),
        });

    loadStatusesNew = (): Observable<IBusStatus[]> =>
        this.busService.fetchBusStatuses({
            from: this.scaleConfiguration.dateRange.from.toUTCString(),
            to: this.scaleConfiguration.dateRange.to.toUTCString(),
        });

    loadTripsNew = (): Observable<ITrip[]> =>
        this.busService.fetchBusTrips(null, {
            from: this.scaleConfiguration.dateRange.from.toUTCString(),
            till: this.scaleConfiguration.dateRange.to.toUTCString(),
        });

    loadAll() {
        const all$ = forkJoin([
            this.getTripsHolderNew(),
            this.loadStatusesNew(),
            this.loadTripsNew(),
        ]).pipe(
            map(([tripsHolders, statuses, trips]) => ({
                tripsHolders,
                statuses,
                trips,
            })),
        );

        all$.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe(
            (data) => {
                // Trips holders
                this.tripsHolders = data.tripsHolders;
                this.calculateNumberOfTripsDefinite();

                // Statuses
                this.statuses$.next(data.statuses);

                // Trips
                this.trips$.next(data.trips);
            },
        );
    }

    getTripsHolder = (): void => {
        const fetchTripsHolders$ = this.planningService
            .fetchTripsHolders({
                from: this.scaleConfiguration.dateRange.from.toUTCString(),
                to: this.scaleConfiguration.dateRange.to.toUTCString(),
            })
            .pipe(takeUntil(this.destroy$))
            .subscribe((tripsHolders) => {
                this.tripsHolders = tripsHolders;
                this.calculateNumberOfTripsDefinite();
            });

        this.subscriptions$.add(fetchTripsHolders$);
    };

    calculateNumberOfTripsDefinite = (): void => {
        const { from, to } = this.scaleConfiguration.dateRange;
        this.numberOfAssignedTrips = this.tripsHolders
            .reduce((a, b) => a.concat(b.trips), [])
            .filter(
                (trip) =>
                    new Date(trip.startDateTime) >= from &&
                    new Date(trip.endDateTime) <= to,
            )
            .filter(
                (trip) =>
                    trip.status.status.status ===
                    TRIP_ASSIGNEMENT_VALUE.ASSIGNED,
            ).length;
    };

    onDateChanged(date: Date): void {
        this.pss.setDate(date);
    }

    showBusCard(vehicle: IPlanboardVehicleView) {
        if (!vehicle.isRental) {
            this.busCardService.setBusCardDataSubject({
                busId: vehicle.id,
            });
            if (this.busToShow && this.busToShow.busId === vehicle.id) {
                return (this.showBusDetail = !this.showBusDetail);
            }
            this.busToShow = vehicle;
            this.showBusDetail = true;
            this.showTripDetail = false;
        }
    }

    closeBusInfo(): void {
        this.showBusDetail = false;
    }

    getMannualTripBackgroundClass(vehicle: IPlanboardVehicleView, trip: any) {
        if (vehicle.isRental) {
            return rowHighlightcolors.r;
        }

        if (
            vehicle.garageName.toLowerCase() !==
            trip.payload.vehicleGarage.toLowerCase()
        ) {
            return rowHighlightcolors.r;
        }

        if (
            trip.payload.isCarTrip &&
            trip.payload.isReturnInternalTrip &&
            trip.payload.isTripStartFromGarage
        ) {
            return rowHighlightcolors.r;
        }
        return rowHighlightcolors.s;
    }

    getBackgroundClass(vehicle: IPlanboardVehicleView, trip: any): string {
        if (!(vehicle && trip)) {
            return "";
        }

        if (vehicle.isRental) {
            const deactivationDate = vehicle.specialVendorTypeDeactivationDate;
            const startTripDate =
                trip.startFromGarageDateTime || trip.startDateTime;
            if (
                deactivationDate &&
                moment(deactivationDate).isBefore(moment(startTripDate))
            ) {
                return rowHighlightcolors.r;
            }
            return rowHighlightcolors.s;
        }

        const tripDate = {
            from: trip.startDateTime,
            to: trip.endDateTime,
        };

        if (!trip.endOn && !trip.startOn) {
            trip.endOn = trip.endToGarageDateTime;
            trip.startOn = trip.startFromGarageDateTime;
        }
        if (!trip.currentSeatConfiguration) {
            trip.currentSeatConfiguration = {
                passengers: trip.passengersForTheTrip,
                name: trip.seatingTypeName,
            };
        }
        const tripSeatingConfig = trip.currentSeatConfiguration.passengers
            ? trip.currentSeatConfiguration
            : trip.seatingConfiguration;
        const isAvailableWIthResiting =
            this.busService.isSuitableSeatingArrangement(
                vehicle.seatingConfigurations,
                tripSeatingConfig,
            );
        const isTrailerBusAttr = vehicle.isTrailer;
        const isWheelchairBusAttr = vehicle.isWheelchairBus;
        const orderAttArr = Object.entries(trip.orderAttributes);
        const currentOrderAttributes = [];
        const tripAttrNames = [];
        orderAttArr.forEach((item, index) => {
            return item[1] === true ? currentOrderAttributes.push(item) : null;
        });
        currentOrderAttributes.forEach((attr) => {
            return tripAttrNames.push(attr[0]);
        });
        const tripHasTrailerAttr = tripAttrNames.includes(
            OrderAttributes.TRAILER,
        );
        const tripHasWheelchairAttr = tripAttrNames.includes(
            OrderAttributes.WHEELCHAIR,
        );
        const isAvailableVehicleOnThisTripDate =
            this.isAvailableVehicleOnThisTripDate(
                vehicle.availabilityStatuses,
                tripDate,
            );
        const isAppropriateOrderAttributes =
            tripHasTrailerAttr && tripHasWheelchairAttr
                ? tripHasTrailerAttr === isTrailerBusAttr &&
                  tripHasWheelchairAttr === isWheelchairBusAttr
                : tripHasTrailerAttr
                  ? tripHasTrailerAttr === isTrailerBusAttr
                  : tripHasWheelchairAttr
                    ? tripHasWheelchairAttr === isWheelchairBusAttr
                    : true;

        const isBusOnTrip = this.isBusOnTrip(vehicle, trip);

        const isVehicleCar = this.busService.isVehicleCar(vehicle);

        const isBusOnActivity = this.isBusOnActivity(vehicle, trip);
        const isWarningColorForGarages =
            trip.payload.garageName &&
            trip.payload.garageName.toLowerCase() !==
                vehicle.garageName.toLowerCase() &&
            trip.payload.label !== HELLOBUS_LABEL &&
            trip.payload.startsFromGarage;
        const isNeedDivide = this.busService.isNeedToDivideTrip(vehicle, trip);

        if (
            isBusOnActivity ||
            isBusOnTrip ||
            !isAvailableVehicleOnThisTripDate
        ) {
            return rowHighlightcolors.r;
        }

        if (
            ((isAvailableWIthResiting || isNeedDivide) &&
                isAvailableVehicleOnThisTripDate) ||
            isVehicleCar
        ) {
            const appropriateSeatingForTrip =
                this.busService.appropriateSeatingForTrip(vehicle, trip);
            if (appropriateSeatingForTrip) {
                if (isVehicleCar && !isAvailableWIthResiting) {
                    return rowHighlightcolors.w;
                } else if (
                    isWarningColorForGarages ||
                    !isAppropriateOrderAttributes
                ) {
                    return rowHighlightcolors.w;
                }
                return rowHighlightcolors.s;
            } else if (isAvailableWIthResiting) {
                return rowHighlightcolors.w;
            }
            return isNeedDivide ? rowHighlightcolors.w : rowHighlightcolors.s;
        } else {
            return rowHighlightcolors.r;
        }
    }

    isBusOnTrip(vehicle: IPlanboardVehicleView, trip: IDraggable) {
        const unplannedTripStartTime = moment
            .utc(trip.startDateTime)
            .seconds(0);
        const unplannedTripEndTime = moment.utc(trip.endDateTime).seconds(0);

        return vehicle.trips.some((vTrip) => {
            const busTripStartTime = moment.utc(vTrip.startDateTime).seconds(0);
            const busTripEndTime = moment
                .utc(
                    vTrip.vehicleGarage && vTrip.endToGarageDateTime
                        ? vTrip.endToGarageDateTime
                        : vTrip.endDateTime,
                )
                .seconds(0);

            /*Start trip time is between bus trip time*/
            const startUnplannedTripIsBetweenBusTripTime =
                unplannedTripStartTime.isBetween(
                    busTripStartTime,
                    busTripEndTime,
                );
            const endUnplannedTripIsBetweenBusTripTime =
                unplannedTripEndTime.isBetween(
                    busTripStartTime,
                    busTripEndTime,
                );
            const starTripIsBetweenUnplannedTripTime =
                busTripStartTime.isBetween(
                    unplannedTripStartTime,
                    unplannedTripEndTime,
                );
            const endTripIsBetweenUnplannedTripTime = busTripEndTime.isBetween(
                unplannedTripStartTime,
                unplannedTripEndTime,
            );

            return (
                startUnplannedTripIsBetweenBusTripTime ||
                endUnplannedTripIsBetweenBusTripTime ||
                starTripIsBetweenUnplannedTripTime ||
                endTripIsBetweenUnplannedTripTime
            );
        });
    }

    isBusOnActivity(vehicle: IPlanboardVehicleView, trip: IDraggable) {
        const unplannedTripStartTime = moment
            .utc(trip.startDateTime)
            .seconds(0);
        const unplannedTripEndTime = moment.utc(trip.endDateTime).seconds(0);

        return vehicle.activities.some((vActivity) => {
            const busActivityStartTime = moment
                .utc(vActivity.startDateTime)
                .seconds(0);
            const busActivityEndTime = moment
                .utc(vActivity.endDateTime)
                .seconds(0);

            /*Start trip time is between bus activity time*/
            const startUnplannedTripIsBetweenBusActivityTime =
                unplannedTripStartTime.isBetween(
                    busActivityStartTime,
                    busActivityEndTime,
                );
            const endUnplannedTripIsBetweenBusActivityTime =
                unplannedTripEndTime.isBetween(
                    busActivityStartTime,
                    busActivityEndTime,
                );
            const startBusActivityIsBetweenUnplannedTrip =
                busActivityStartTime.isBetween(
                    unplannedTripStartTime,
                    unplannedTripEndTime,
                );
            const endBusActivityIsBetweenUnplannedTrip =
                busActivityEndTime.isBetween(
                    unplannedTripStartTime,
                    unplannedTripEndTime,
                );

            return (
                startUnplannedTripIsBetweenBusActivityTime ||
                endUnplannedTripIsBetweenBusActivityTime ||
                startBusActivityIsBetweenUnplannedTrip ||
                endBusActivityIsBetweenUnplannedTrip
            );
        });
    }

    isAvailableVehicleOnThisTripDate(
        vehicleAvailability: IVehicleAvailability[],
        tripDate: { from: string; to: string },
    ) {
        if (!vehicleAvailability.length) {
            return true;
        }

        const isNotAvailable = vehicleAvailability.some(
            (availabilityStatus) => {
                if (
                    availabilityStatus.status !==
                    VehicleAvailabilityStatusesEnum.ACTIVE
                ) {
                    const startStatusDate = moment.utc(
                        availabilityStatus.startDate ||
                            this.scaleConfiguration.dateRange.from,
                    );
                    const endStatusDate = moment.utc(
                        availabilityStatus.endDate ||
                            this.scaleConfiguration.dateRange.to,
                    );
                    const startTripDate = moment(tripDate.from);
                    const endTripDate = moment(tripDate.to);

                    return (
                        (startTripDate.isSameOrAfter(startStatusDate) &&
                            startTripDate.isBefore(endStatusDate)) ||
                        (startTripDate.isSameOrBefore(startStatusDate) &&
                            endTripDate.isAfter(startStatusDate))
                    );
                }

                return false;
            },
        );

        return !isNotAvailable;
    }

    onScaleIndexChanged(scaleIndex: number): void {
        this.pss.setScale(scaleIndex);
    }

    removeTripCard(): void {
        this.VCR.clear();
        this.componentReference = null;
    }

    emitVehicleTypeChangesProcessing(value: string): void {
        this.vehicleToShow$.next(value);
    }

    vehicleTypeChanged({ value }: SwitchToggleConfigOption): void {
        this.userPlanboardBusesTabSwitchersSettings.vehicleType =
            value as ToggleVehicleType;
        this.userSettingsService.saveUserSettings(
            PLANBOARD_BUSES_TAB_USER_SETTINGS_KEY,
            this.userPlanboardBusesTabSwitchersSettings,
        );
        this.emitVehicleTypeChangesProcessing(value);
    }

    emitDurationTimeTypeChangesProcessing(value: string): void {
        this.grossTime$.next(isGrossType(value));
        this.pss.grossTimeChanged$.next(isGrossType(value));
        this.isGrossTimeOn = isGrossType(value);
    }

    durationTimeTypeChanged({ value }: SwitchToggleConfigOption): void {
        this.userPlanboardBusesTabSwitchersSettings.durationTimeType =
            value as ToggleDurationTimeType;
        this.userSettingsService.saveUserSettings(
            PLANBOARD_BUSES_TAB_USER_SETTINGS_KEY,
            this.userPlanboardBusesTabSwitchersSettings,
        );
        this.emitDurationTimeTypeChangesProcessing(value);
    }

    emitAvailabilityStatusChangedProcessing(value: string): void {
        this.isOnlyAvailable$.next(value !== ToggleAvailabilityStatus.ALL);
    }

    availabilityStatusChanged({ value }: SwitchToggleConfigOption): void {
        this.userPlanboardBusesTabSwitchersSettings.availabilityStatus =
            value as ToggleAvailabilityStatus;
        this.userSettingsService.saveUserSettings(
            PLANBOARD_BUSES_TAB_USER_SETTINGS_KEY,
            this.userPlanboardBusesTabSwitchersSettings,
        );
        this.emitAvailabilityStatusChangedProcessing(value);
    }

    emitCreationModeChangedProcessing(value: string) {
        this.selectedPlanboardMode = value;
    }
    creationModeChanged({ value }: SwitchToggleConfigOption): void {
        this.userPlanboardBusesTabSwitchersSettings.creationMode =
            value as ToggleCreationMode;
        this.userSettingsService.saveUserSettings(
            PLANBOARD_BUSES_TAB_USER_SETTINGS_KEY,
            this.userPlanboardBusesTabSwitchersSettings,
        );
        this.emitCreationModeChangedProcessing(value);
    }

    onBusHover(index: number) {
        if (index < 0 || (index !== 0 && !index)) {
            return;
        }
        setTimeout(() => {
            this.highlightedIndex$.next(index);
        }, MOUSE_OVER_DEBOUNCE_TIME * 1.1);
    }

    getExtraIndex(
        planBoardData: IPlanboardVehicleDataView[],
        itemIndex: number,
    ): number {
        return planBoardData[itemIndex - 1]
            ? planBoardData
                  .slice(0, itemIndex)
                  .reduce((acc, x) => acc + x.vehicles.length, 0)
            : 0;
    }

    getHighlightClassName(vehicle: IPlanboardVehicleView): string {
        if (
            vehicle === undefined ||
            vehicle.index === undefined ||
            this.selectedBusIndex === undefined ||
            !this.isDragging
        ) {
            return "";
        }
        return this.selectedBusIndex === vehicle.index
            ? this.selectedBusClassName
            : "";
    }

    // Note: The methods 'openActionsDialog' and 'prepareAmountsOfActionsBasedOnFilters' became obsolete and are currently unused.
    // This change was implemented following the removal of the 'Actions' button from the vehicle tab, as detailed in issue ticket #1831.
    openActionsDialog(): void {
        if (this.componentReference) {
            this.removeTripCard();
            return;
        }

        const componentFactory =
            this.componentFactoryResolver.resolveComponentFactory(
                DriversPopupComponent,
            );
        this.componentReference = this.VCR.createComponent(componentFactory);
        this.componentReference.instance.parentRef = this;
        this.componentReference.instance.trips = this.tripsOnCurrentDay;
        this.componentReference.instance.totalTrips =
            this.displayedTripStatuses;
        const closePopUpSub$ = this.componentReference.instance.dismiss
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.removeTripCard();
            });
        this.subscriptions$.add(closePopUpSub$);
    }

    prepareAmountsOfActionsBasedOnFilters(
        transformedData: IPlanboardVehicleDataView[],
    ): void {
        const startDate = moment(this.startCurrentDate);
        const endDate = moment(this.endCurrentDate);

        const allTrips: IPlanboardTrip[] = transformedData.reduce(
            (acc: IPlanboardTrip[], garage): IPlanboardTrip[] => {
                return acc.concat(
                    garage.vehicles.reduce(
                        (
                            trips: IPlanboardTrip[],
                            vehicle,
                        ): IPlanboardTrip[] => {
                            return trips.concat(vehicle.trips);
                        },
                        [],
                    ),
                );
            },
            [],
        );

        this.tripsOnCurrentDay = allTrips.reduce(
            (acc: IPlanboardTrip[], trip): IPlanboardTrip[] => {
                if (!trip) {
                    return acc;
                }

                const tripStartDate = moment(trip.startDateTime);
                if (tripStartDate.isBetween(startDate, endDate)) {
                    acc.push(trip);
                }
                return acc;
            },
            [],
        );

        this.displayedTripStatuses = calcStatuses(this.tripsOnCurrentDay);
    }

    private getTransformedOriginalData(
        originalData: IPlanboardVehicleDataView[],
    ): Observable<IPlanboardVehicleDataView[]> {
        const availabilitySettings = {
            isOnlyAvailable$: this.isOnlyAvailable$,
            currentPeriod: this.busesFilter,
        };

        let filteredData = this.rowIndexPipe.transform(of(originalData));
        filteredData = this.carFilterPipe.transform(
            filteredData,
            this.vehicleToShow$,
        );
        filteredData = this.vehicleAvailabilityPipe.transform(
            filteredData,
            availabilitySettings,
        );
        filteredData = this.filterPipe.transform(filteredData);
        return filteredData;
    }

    private getTransformedCanvasData(
        filteredData: IPlanboardVehicleDataView[],
    ): Observable<ICanvasDataItem[]> {
        const settings = {
            grossTime$: this.grossTime$,
            clickHandlers: this.clickHandlers,
            isMdtPlaning: this.isMdtPlaning,
            dateRange: this.scaleConfiguration.dateRange,
        };

        return this.canvasPipe.transform(filteredData, settings);
    }

    private subscribeToPlanBoardData() {
        combineLatest([
            this.planBoardData$,
            this.grossTime$,
            this.isOnlyAvailable$,
        ])
            .pipe(
                switchMap(([originalData]) =>
                    this.getTransformedOriginalData(originalData),
                ),
                tap(
                    (transformedData) =>
                        (this.transformedPlanboardData = transformedData),
                ),
                switchMap(this.getTransformedCanvasData.bind(this)),
                takeUntil(this.destroy$),
                pairwise(),
            )
            .subscribe(([previousCanvas, currentCanvas]) => {
                this.zone.run(() => {
                    const isCanvasDataChanged = !_.isEqual(
                        previousCanvas,
                        currentCanvas,
                    );
                    // if count of items wasn't changed, and it was 0, and current is 0, mutation observer - won't be triggered
                    // or if canvas data wasn't changed
                    if (
                        (currentCanvas && currentCanvas.length) === 0 ||
                        !isCanvasDataChanged
                    ) {
                        this.loading = false;
                    }
                });
                this.canvasData = currentCanvas;
            });

        this.changeDetectorRef.detectChanges();
    }

    private subscribeToUserSettings(): void {
        this.userSettingsService
            .getUserSetting(PLANBOARD_BUSES_TAB_USER_SETTINGS_KEY)
            .subscribe((result) => {
                this.userPlanboardBusesTabSwitchersSettings =
                    result as UserPlanboardBusesTabSwitchersSettings;
                this.configureTogglesByUserSettings(result);
            });
    }

    private pipeShowPlanning(): void {
        this.showPlanning$ = this.showPlanning$.pipe(takeUntil(this.destroy$));
    }

    private subscribeToVariousServices(): void {
        try {
            this.tripCardService.tripCardDataSubject
                .pipe(takeUntil(this.destroy$))
                .subscribe((res) => {
                    this.closeBusInfo();
                });

            this.tripCardService.tripAssignChanged
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    this.showTripDetail = true;
                });

            this.planningService.currentTripSubject$.next({
                tripType: ACTIVE_TAB.BUSES,
            });

            this.isMdtPlaning = this.planningService.isMtdTrip.value;

            this.pss.grossTimeChanged$
                .pipe(takeUntil(this.destroy$))
                .subscribe((res) => {
                    this.grossTime$.next(res);
                    this.isGrossTimeOn = res;
                });

            this.busCardService.activityStatusChanged$
                .pipe(takeUntil(this.destroy$))
                .subscribe((res) => {
                    if (res) {
                        this.fetchData();
                    }
                });
            this.busCardService.busTabLoaderHandler$
                .pipe(takeUntil(this.destroy$))
                .subscribe((res) => {
                    this.loading = res;
                });
        } catch (error) {
            console.log(
                "Error occurred while subscribing to subscribeToVariousServices :",
                error.message,
            );
        }
    }

    private subscribeToFilterChanges(): void {
        const filterChangesSubscription$ = combineLatest([
            this.pss.scaleConfigurationChanged$.pipe(
                startWith(DEFAULT_SCALE_CONFIGURATION),
            ),
        ])
            .pipe(
                tap(() => (this.loading = true)),
                debounceTime(700),
                distinctUntilChanged(),
                takeUntil(this.destroy$),
            )
            .subscribe((scaleConfiguration) => {
                this.handleScaleConfiguration(scaleConfiguration[0]);
            });

        this.subscriptions$.add(filterChangesSubscription$);
    }

    private handleScaleConfiguration(scaleConfiguration: any): void {
        if (scaleConfiguration.isDefault) {
            this.loading = false;
            return;
        }

        if (scaleConfiguration.scaleConfigurationIndex === 0) {
            this.loading = false;
            return;
        }

        if (scaleConfiguration === DEFAULT_SCALE_CONFIGURATION) {
            scaleConfiguration = this.pss.currentScaleConfiguration;
        }

        const busesFilter: Partial<IBusesFilter> = {
            dateFrom: scaleConfiguration.dateRange.from,
            dateTo: scaleConfiguration.dateRange.to,
        };

        this.startCurrentDate = scaleConfiguration.dateRange.from;
        this.endCurrentDate = scaleConfiguration.dateRange.to;

        const criteriaKeys = {
            busRental: "on",
            search: "query",
            busAssignement: "value",
        };

        this.busesFilter = busesFilter as IBusesFilter;
        this.fetchData();
    }

    private subscribeToStopLoading(): void {
        this.stopLoading$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.loading = false;
            safeDetectChanges(this.changeDetectorRef);
            if (this.mutationObserver) {
                this.mutationObserver.disconnect();
                this.mutationObserver = null;
            }
        });
    }

    private openMannualTripCreate(args) {
        const componentFactory =
            this.componentFactoryResolver.resolveComponentFactory(
                MannualTripCardComponent,
            );
        const componentRef = this.container.createComponent(componentFactory);
        const component = componentRef.instance;

        component.payload = {
            startDate: args.date,
            isCar: args.data.isCar,
            garageId: args.event.data.garageId,
            vehicleId: args.data.id,
            isNew: true,
            isReturnTrip: false,
            number: args.data.number,
        };

        component.dismiss
            .pipe(takeUntil(this.destroy$))
            .subscribe((value: DismissData) => {
                componentRef.destroy();
                this.canvasEventService.selectRowByTrip$.next({
                    index: null,
                    contextName: CanvasContextNames.busses,
                });

                const isSameGarage = !!(
                    value.payload && value.payload.isSameGarage
                );
                if (
                    value.action !== ActivityModalActions.Close &&
                    !isSameGarage
                ) {
                    this.openMannualReturnConfirm(value.payload);
                }

                if (isSameGarage) {
                    this.canvasEventService.selectRowByTrip$.next({
                        index: null,
                        contextName: CanvasContextNames.busses,
                    });
                    this.busCardService.activityStatusChanged$.next(true);
                }
            });
    }

    private openMannualReturnConfirm(value) {
        const {
            isCar,
            trip,
            selectedDrivers,
            number,
            startDate,
            endDate,
            parentTripId,
            vehicleGarageId,
        } = value;
        const tripPoints = trip && trip.points;
        const [startPoint, endPoint] = tripPoints;
        const isDestinationGarage = endPoint.isGarage;

        const options: IModalOpenOptions = {
            data: { isCar, isDestinationGarage },

            ngbOptions: {
                centered: true,
                windowClass: "ask-for-mannual-return",
            },
        };

        this.modalService
            .open(MannualReturnTripDialogComponent, options)
            .pipe(takeUntil(this.destroy$))
            .subscribe((config) =>
                this.processMannualReturnConfirm(
                    config,
                    trip,
                    selectedDrivers,
                    isCar,
                    number,
                    startDate,
                    endDate,
                    parentTripId,
                    vehicleGarageId,
                ),
            );
    }

    private processMannualReturnConfirm(
        config: { withSameDrivers: boolean; withOtherDrivers: boolean },
        newTrip: MannualTripNew,
        selectedDrivers: IAsigneeDriver[],
        isCar,
        number,
        startDate,
        endDate,
        parentTripId,
        vehicleGarageId,
    ) {
        const { withSameDrivers, withOtherDrivers } = config;

        if (withSameDrivers || withOtherDrivers) {
            const componentFactory =
                this.componentFactoryResolver.resolveComponentFactory(
                    MannualTripCardComponent,
                );
            const componentRef =
                this.container.createComponent(componentFactory);
            const component = componentRef.instance;
            component.isNewReturnTrip = true;
            component.isNew = true;
            const newEndDate = moment(endDate)
                .add(
                    moment(endDate).diff(moment(startDate), "minutes"),
                    "minutes",
                )
                .toDate();
            component.payload = {
                ...newTrip,
                number,
                isCar,
                parentTripId,
                startDateTime: endDate,
                endDateTime: newEndDate,
                isReturnTrip: true,
                selectedDrivers: withOtherDrivers ? [] : selectedDrivers,
                vehicleGarageId,
            };

            component.dismiss.pipe(takeUntil(this.destroy$)).subscribe(() => {
                componentRef.destroy();
                this.canvasEventService.selectRowByTrip$.next({
                    index: null,
                    contextName: CanvasContextNames.busses,
                });
                this.busCardService.activityStatusChanged$.next(true);
            });
        } else {
            this.busCardService.activityStatusChanged$.next(true);
        }
    }

    private assignBus(
        vehicle: any,
        showNitification: () => void,
        tripDate: { from: string; to: string },
        trip: IDraggable,
    ) {
        if (vehicle.isRental) {
            const deactivationDate = vehicle.specialVendorTypeDeactivationDate;

            if (
                deactivationDate &&
                moment(deactivationDate).isBefore(moment(tripDate.from))
            ) {
                return;
            }
        }
        if (vehicle.vehicleType === "CarVehicle") {
            return;
        }

        showNitification();
    }

    private fetchVehicleType(id: number): Promise<string> {
        return this.planningService.fetchVehicleType(id).toPromise();
    }

    private handleBroadcastChannel(): void {
        this.channel.onmessage = (message) => {
            const trip: OverlappingTrip = message.data;
            const { tripId, tripNumber, tripDate } = trip;

            if (!this.isDateInInterval(tripDate)) {
                this.onDateChanged(tripDate);
            }

            this.onClick(tripNumber, tripId);
        };
    }

    private isDateInInterval(date: Date): boolean {
        return moment(date).isBetween(
            this.scaleConfiguration.dateRange.from,
            this.scaleConfiguration.dateRange.to,
        );
    }

    private onCanvasScroll($event: ScrollDirection) {
        const step = 60;
        const { scrollTop } = this.scrollContainer.nativeElement;

        if ($event === ScrollDirection.Up) {
            this.setScrollPosition(scrollTop - step);
        } else {
            this.setScrollPosition(scrollTop + step);
        }
    }

    private reassignBusToTripRequest(
        vehicle: IPlanboardVehicleView,
        trip: any,
        skipNonBlockingValidation = false,
        seatingConfig,
        busComingFromGarage = true,
        busReturnToGarage = true,
    ) {
        this.loaderService.startLoading();

        const successHandler = (responce) => {
            const { tripNumber, passengersUpdated } = responce;

            const baseActions = () => {
                if (passengersUpdated) {
                    this.tripCardService.tripDataChanged.next(true);
                }
                this.loaderService.stopLoading();
                this.fetchData();
                this.busCardService.activityStatusChanged$.next(true);
                this.assignNotificationService.showNotificationOnBusAssign(
                    vehicle,
                    tripNumber,
                );
            };

            if (this.showTripDetail) {
                this.tripCardService.reloadTripCard();
            }
            baseActions();
        };

        const errorHandler = (errors) => {
            this.loaderService.stopLoading();

            return throwError(errors);
        };

        const reassignRequest = (
            preparedVehicle,
            preparedTrip,
            busComingFromGarage,
            busReturnToGarage,
        ) => {
            return this.busService
                .reassignBusToTrip(
                    preparedVehicle,
                    preparedTrip,
                    skipNonBlockingValidation,
                    busComingFromGarage,
                    busReturnToGarage,
                )
                .pipe(
                    tap(successHandler),
                    tap((assignedResponse: any) => {
                        this.loading = true;
                        this.changeDetectorRef.detectChanges();
                        this.dragAndDropService.emit(
                            DragAndDropEventTypes.onDrop,
                        );
                        if (!trip.payload.orderType) {
                            this.tripCardService.setTripCardDataSubject({
                                currentTripHolderId: null,
                                currentTripId: null,
                                currentMannualTripId: trip.payload.tripId,
                                type: "buses",
                            });
                        } else {
                            this.tripCardService.setTripCardDataSubject({
                                currentTripHolderId: trip.payload.tripHolderId,
                                currentTripId: assignedResponse.id,
                                type: "buses",
                            });
                        }
                        this.busCardService.activityStatusChanged$.next(true);
                        this.changeDetectorRef.detectChanges();
                    }),
                    catchError(errorHandler),
                );
        };

        this.loaderService.startLoading();
        if (this.currentBus.isRental) {
            return this.busService
                .reassignRentalVehicleToTrip(vehicle, trip, seatingConfig)
                .pipe(tap(successHandler), catchError(errorHandler));
        }

        return this.planningService.restoreGaragesPoints(vehicle, trip).pipe(
            mergeMap(
                ([pointsWithRestoredGarage, splittedAt, isMultidayOrder]) => {
                    if (
                        isMultidayOrder &&
                        splittedAt === SplittedTypes.destination
                    ) {
                        busComingFromGarage = trip.isTripStartFromGarage;
                        busReturnToGarage = trip.isTripEndToGarage;
                    }

                    if (pointsWithRestoredGarage.length) {
                        return reassignRequest(
                            vehicle,
                            { trip, points: pointsWithRestoredGarage },
                            busComingFromGarage,
                            busReturnToGarage,
                        );
                    }
                    return reassignRequest(
                        vehicle,
                        trip,
                        busComingFromGarage,
                        busReturnToGarage,
                    );
                },
            ),
        );
    }

    private reassignBusOnATrip(vehicle: IPlanboardVehicleView, trip: any) {
        this.startLoading(ASSIGN_ACTION.REASSIGN);
        let seatingConfig = null;
        const request = (vehicle, trip, seatingConfig) => {
            trip.seatingConfiguration = {
                passengers: this.currentTrip.payload.passengersForTheTrip,
                name: this.currentTrip.payload.seatingTypeName,
            };
            trip.endOn = trip.endToGarageDateTime;
            this.reassignRequest(vehicle, trip, seatingConfig);
        };

        const obs$ = vehicle.isRental
            ? of(null).pipe(
                  mergeMap(() => {
                      this.loading = false;
                      this.changeDetectorRef.detectChanges();
                      setTimeout(() => this.app.tick(), 10);
                      return this.seatsService
                          .openBusSeatEdit({
                              count: this.currentTrip.passengersForTheTrip,
                              type: this.currentTrip
                                  ? this.currentTrip.seatingTypeName
                                  : null,
                          })
                          .pipe(
                              filter(Boolean),
                              tap(() => (this.loading = true)),
                          );
                  }),
              )
            : of(null);

        const restoreGarages$ = () => {
            return this.tripsService
                .getTripsHolderPoints(trip.tripHolderId)
                .pipe(
                    mergeMap((data) => {
                        const {
                            points,
                            startFromGarage,
                            endToGarage,
                            startGarageTypeId,
                            endGarageTypeId,
                        } = data;

                        trip.points = points;
                        trip.startFromGarage = startFromGarage;
                        trip.endToGarage = endToGarage;
                        trip.startGarageTypeId = startGarageTypeId;
                        trip.endGarageTypeId = endGarageTypeId;
                        trip.points = trip.points.map((point, index) => {
                            point.garageType = null;
                            point.isGarage = false;

                            if (index === 0) {
                                point.garageType =
                                    GarageTypes[startGarageTypeId] || null;
                                point.isGarage = startFromGarage;
                            }

                            if (index === trip.points.length - 1) {
                                point.garageType =
                                    GarageTypes[endGarageTypeId] || null;
                                point.isGarage = endToGarage;
                            }

                            return point;
                        });
                        if (vehicle.isRental) {
                            const newPoints =
                                this.pointsService.prepareRentalVehiclePoints(
                                    trip,
                                );
                            return of(newPoints);
                        }
                        const firstPointName = points[0].name.toLowerCase();
                        const lastPointName =
                            points[points.length - 1].name.toLowerCase();
                        const busGarage = this.currentBus.garageName;
                        if (
                            (firstPointName === busGarage ||
                                lastPointName === busGarage) &&
                            firstPointName !== lastPointName &&
                            !this.currentTrip.garageName
                        ) {
                            return of(this.pointsService.preparePoints(points));
                        }
                        trip.payload.startFromGarage = trip.startFromGarage;
                        trip.payload.endToGarage = trip.endToGarage;
                        const prevBusIsRental = this.vehicles.find(
                            (vehicle) => vehicle.id === trip.assignedBusId,
                        ).isRental;
                        let getAlternativeGaragesReq: Observable<
                            Record<string, any>
                        >;

                        if (trip.orderId) {
                            getAlternativeGaragesReq = this.planningService
                                .fetchOrderAlternativeGarages(trip.orderId)
                                .pipe(
                                    map((data) => {
                                        trip.orderGarages = data.orderGarages;
                                        trip.orderRoute = data.orderRoute;
                                        return of(trip);
                                    }),
                                );
                        } else {
                            getAlternativeGaragesReq = of(trip);
                        }
                        return getAlternativeGaragesReq.pipe(
                            switchMap((data) => {
                                return this.pointsService
                                    .preparePointsToRequest(
                                        trip,
                                        vehicle,
                                        prevBusIsRental,
                                    )
                                    .pipe(
                                        map((data) => {
                                            if (data) {
                                                trip.orderGarages =
                                                    data.orderGarages;
                                            }
                                            return data && data.points;
                                        }),
                                    );
                            }),
                        );
                    }),
                    takeUntil(this.destroy$),
                );
        };

        obs$.pipe(
            take(1),
            switchMap((seatConfig) => {
                seatingConfig = seatConfig;
                if (!trip.orderGarage) {
                    return of(null);
                }

                return restoreGarages$();
            }),
        ).subscribe((points) => {
            trip.points = points;

            request(vehicle, trip, seatingConfig);
        });

        this.changeDetectorRef.detectChanges();
    }

    private reassignRequest(
        vehicle: IPlanboardVehicleView,
        trip: any,
        seatingConfig?,
    ) {
        this.loaderService.startLoading();
        let bus;

        this.reassignBusToTripRequest(vehicle, trip, false, seatingConfig)
            .pipe(
                tap(() => {
                    if (
                        trip.orderAttributes &&
                        trip.orderAttributes.trailer &&
                        this.currentBus.isTrailer
                    ) {
                        const modalOptions: IModalOpenOptions = {
                            data: {
                                text: "busListPage.trailerWarningModal.text",
                            },
                            ngbOptions: { centered: true },
                        };
                        this.modalService.open(
                            WarningTrailerPopupComponent,
                            modalOptions,
                        );
                    }
                }),
                finalize(() => this.loaderService.stopLoading()),
                catchError((err) => {
                    const gqlErrors = err.graphQLErrors;
                    let isBlockingValidation = false;
                    if (gqlErrors && gqlErrors.length) {
                        gqlErrors.forEach((error) => {
                            if (
                                error.extensions &&
                                error.extensions.code &&
                                error.extensions.code === BLOCKING_VALIDATION
                            ) {
                                isBlockingValidation = true;
                            }
                        });
                    }
                    this.overlapAssignmentService.isShuttle =
                        this.isShuttleOrTransvision;
                    this.stopLoadingOnMessageShow();
                    this.zone.run(() => {
                        this.overlapAssignmentService
                            .processErrors(err)
                            .pipe(take(1), takeUntil(this.destroy$))
                            .subscribe((result) => {
                                this.stopLoading$.emit();
                                if (isBlockingValidation) {
                                    return;
                                }
                                if (result) {
                                    const {
                                        assignDivide,
                                        busComingFromGarage,
                                        busReturnToGarage,
                                    } = result;

                                    if (assignDivide) {
                                        this.onDivideReassign(
                                            vehicle,
                                            trip,
                                            busComingFromGarage,
                                            busReturnToGarage,
                                        );
                                        return;
                                    }

                                    const skipNonBlockingValidation = true;
                                    this.startLoading(ASSIGN_ACTION.REASSIGN);

                                    this.reassignBusToTripRequest(
                                        vehicle,
                                        trip,
                                        skipNonBlockingValidation,
                                        seatingConfig,
                                        busComingFromGarage,
                                        busReturnToGarage,
                                    )
                                        .pipe(
                                            takeUntil(this.destroy$),
                                            tap((data) => {
                                                if (
                                                    trip.orderAttributes &&
                                                    trip.orderAttributes
                                                        .trailer &&
                                                    vehicle.isTrailer
                                                ) {
                                                    const modalOptions: IModalOpenOptions =
                                                        {
                                                            data: {
                                                                text: "busListPage.trailerWarningModal.text",
                                                            },
                                                            ngbOptions: {
                                                                centered: true,
                                                            },
                                                        };
                                                    this.modalService.open(
                                                        WarningTrailerPopupComponent,
                                                        modalOptions,
                                                    );
                                                }
                                            }),
                                        )
                                        .subscribe();
                                    return;
                                }
                            });
                    });
                    return throwError(err);
                }),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    private stopLoadingOnMessageShow(): void {
        setTimeout(() => {
            this.loading = false;
            this.tripCardService.tripCardLoaderHandler$.next(false);
            this.tripCardService.unplannedTripsLoaderHandler$.next(false);
        });
    }

    showDriverCard(driver: IDriver): void {
        if (!driver.vendorName) {
            this.driverCardService.setDriverCardDataSubject({
                driverId: driver.id,
            });
            if (this.driverToShow && this.driverToShow.driverId === driver.id) {
                this.isDriverInfoShown = !this.isDriverInfoShown;
                return;
            }
            this.driverToShow = driver;
            this.isDriverInfoShown = true;
        }
    }

    closeDriverCard(): void {
        this.isDriverInfoShown = false;
    }

    getVehicleSeatsTemplateOptions(
        vehicle: IPlanboardVehicleView,
        seatingType: string,
    ): ISeatConfigurationOptions {
        if (vehicle.isRental) {
            const {
                assignedThirdPartyBusSeats,
                assignedThirdPartyBusSeatsType,
            } = vehicle.trips[0];
            const rentalOptions = {
                seats:
                    assignedThirdPartyBusSeatsType ===
                    SEAT_CONFIGURATION_SHORTCUTS_MAP[seatingType]
                        ? assignedThirdPartyBusSeats
                        : 0,
                color: "#f3f4f7",
                restSeats: 0,
            };
            return rentalOptions;
        }

        return vehicle[seatingType];
    }
}
