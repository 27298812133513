import { Pipe, PipeTransform } from "@angular/core";
import { Observable, combineLatest, BehaviorSubject } from "rxjs";
import { IBus } from "../models/bus-general.interface";
import { IBusStatus } from "../../drivers/models/driver-general.interface";

@Pipe({
    name: "status",
    pure: true,
})
export class StatusPipe implements PipeTransform {
    transform(
        buses$: Observable<IBus[]>,
        statuses$: BehaviorSubject<IBusStatus[]>,
    ): Observable<IBus[]> {
        return combineLatest(
            buses$,
            statuses$,
            (buses: IBus[], statuses: IBusStatus[]) => {
                if (!buses || !buses.length) {
                    return [];
                }
                if (!statuses || !statuses.length) {
                    return buses;
                }
                const res = buses.map((bus: IBus) => ({
                    ...bus,
                    statuses: statuses
                        .flat()
                        .filter((status) => status.busId === bus.id),
                }));
                return res;
            },
        );
    }
}
