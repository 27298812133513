import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "euronorm",
    pure: true,
})
export class EuronormPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) {
            return "";
        }
        const euroDigit = value.match(/\d+/).shift();
        return euroDigit || "";
    }
}
