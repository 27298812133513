import { IFilter } from "../../../shared/models/filters/filter.interface";
import { FilterEvent } from "../../../shared/models/filters/filter-event.interface";
import { IPlanboardVehicleView } from "../models/vehicle.model";
import { IPlanboardVehicleDataView } from "../../models/planboard-data.model";

export class BusTypeFilter implements IFilter {
    private event: FilterEvent;

    constructor(event?: FilterEvent) {
        this.event = event;
    }

    meets(
        planboardData: IPlanboardVehicleDataView[],
    ): IPlanboardVehicleDataView[] {
        if (!this.event) {
            return planboardData;
        }
        if (!this.event.criteria) {
            return planboardData;
        }
        if (!this.event.criteria.length) {
            return planboardData;
        }

        const matches = (type: string) => (value: string) => type === value;
        const byVehicleType = (vehicle: IPlanboardVehicleView) =>
            this.event.criteria
                .map((criteria) => criteria.value)
                .some(matches(vehicle.busType));

        const reduce = (res, data) => {
            const vehicles = data.vehicles.filter(byVehicleType);
            return vehicles.length ? [...res, { ...data, vehicles }] : res;
        };

        return planboardData.reduce(reduce, []);
    }
}
